/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from "react";
import {
  MaterialEntity,
  PackagingComponentData,
  PackagingDataType,
  PackagingLevelData,
} from "../../structures/packaging";
import { SelectChangeEvent } from "@mui/material";
import {
  ApiEndPoints,
  ApiEndPointsURL,
} from "../../constants/ApiEndPoints.constant";
import { toast } from "react-toastify";
import axios from "axios";
import { ProductDataContext } from "../../contexts/productData/ProductDataContext";
import { useGlobaldata } from "../../contexts/masterData/DataContext";
import { ResultDataContext } from "../../contexts/resultData/ResultDataContext";

type IndexValuePair = { index: string; value: boolean };
const useConsumerPackaging = () => {
  const [packagingAllData, setPackagingAllData] = useState<PackagingDataType>({
    packaging_level: [],
  });
  const [packagingSavedData, setPackagingSavedData] = useState<PackagingDataType>({
    packaging_level: [],
  });
  const [primaryData, setPrimaryData] = useState<PackagingComponentData[]>([]);
  const [secondaryData, setSecondaryData] = useState<PackagingComponentData[]>([]);
  const [isPrimaryAddEnabled, setIsPrimaryAddEnabled] = useState(true);
  const [isSecondaryAddEnabled, setIsSecondaryAddEnabled] = useState(true);
  const [resetData, setResetData] = useState(false);
  const [productEvacuationValue, setProductEvacuationValue] = useState("");
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isComponentDataChangePrimary, setIsComponentDataChangePrimary] =
    useState<IndexValuePair[]>([]);
  const [isComponentDataChangeSecondary, setIsComponentDataChangeSecondary] =
    useState<IndexValuePair[]>([]);
  const [counterPrimary, setCounterPrimary] = useState(0);
  const [counterSecondary, setCounterSecondary] = useState(0);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isOneTimeSaveDone, setIsOneTimeSaveDone] = useState(false);
  const { token } = useGlobaldata();
  const {
    productData,
    assessmentsData,
    assessmentsType,
    refetch,
    formulation,
    packagingData,
    secondaryPackaging,
    primaryPackaging,
  } = useContext(ProductDataContext);
  const{
    resultDataRefetch,
  } = useContext(ResultDataContext);
  const [primaryRecycleStatus, setPrimaryRecycleStatus] =
    useState<string>("N/A");
  const [secondaryRecycleStatus, setSecondaryRecycleStatus] =
    useState<string>("N/A");
  
  const handleChangeClickValue = (
    index: number,
    type: "Primary" | "Secondary"
  ) => {
    if (type === "Primary" && primaryData?.length) {
      if (primaryData[index].pc_nm !== "") {
        setIsComponentDataChangePrimary((prevState) => {
          const indVal = index + "p";
          const existingItem = prevState.find((item) => item.index  === indVal);
          // If the index exists with false value, don't update it
          if (existingItem && existingItem.value === false) {
            return prevState;
          }
          // If the index exists with true, no change is needed
          if (existingItem && existingItem.value === true) {
            return prevState;
          }
          // If the index doesn't exist, add it with value true
          return [...prevState, { index:indVal, value: true }];
        });
      }
    }
    if (type === "Secondary" && secondaryData?.length) {
      if (secondaryData[index].pc_nm !== "") {
        setIsComponentDataChangeSecondary((prevState) => {
          const indexVal = index + "s";
          const existingItem = prevState.find((item) => item.index === indexVal);
          // If the index exists with false value, don't update it
          if (existingItem && existingItem.value === false) {
            return prevState;
          }
          // If the index exists with true, no change is needed
          if (existingItem && existingItem.value === true) {
            return prevState;
          }
          // If the index doesn't exist, add it with value true
          return [...prevState, { index: indexVal, value: true }];
        });
      }
    }
  };
  const hasNonEmptyValue = (data: PackagingComponentData[]): boolean => {
    return data?.every((item) => {
      return Object.entries(item).some(([key, value]) => {
        // Exclude fields that should not be checked
        if (
          [
            "_id",
            "stage",
            "state",
            "template",
            "isEdited",
            "isDataComplete",
          ].includes(key)
        ) {
          return false;
        }

        // Check if 'material' is a non-empty array
        if (key === "material" && Array.isArray(value)) {
          return value.length > 0;
        }

        // For other fields, check if they are not empty
        return value !== null && value !== undefined && value !== "";
      });
    });
  };

  const handleDeleteComponent = useCallback(
    (index: number, type: "Primary" | "Secondary") => {
      setIsSaveEnabled(true);
      if (type === "Primary") {
        setPrimaryData((prevData) => prevData.filter((_, i) => i !== index));
      }
      if (type === "Secondary") {
        setSecondaryData((prevData) => prevData.filter((_, i) => i !== index));
      }
    },
    []
  );

  // Utility function to check if PackagingComponentData is empty
  const isPackagingComponentDataEmpty = (
    component: PackagingComponentData
  ): boolean => {
    const {
      pc_nm,
      description,
      color,
      opacity,
      component_type,
      weight,
      opacifier,
      material,
      recyclability_status,
      finishing_process,
    } = component;
    const emptyOrUndefined = (value: undefined | string) => {
      return !value || value === "";
    };
    return (
      emptyOrUndefined(pc_nm) &&
      emptyOrUndefined(description) &&
      emptyOrUndefined(color) &&
      emptyOrUndefined(opacity) &&
      emptyOrUndefined(component_type) &&
      emptyOrUndefined(weight) &&
      emptyOrUndefined(opacifier) &&
      emptyOrUndefined(recyclability_status) &&
      emptyOrUndefined(finishing_process) &&
      Array.isArray(material) &&
      material.length === 0 // Check if material is an empty array
    );
  };

  
  const handleSavePacking = useCallback(() => {
    handleSaveCalculatePacking(false)
  }, [packagingAllData, primaryData, secondaryData]);

  const handleSaveCalculatePacking = useCallback(async (isAutoSave: boolean) => {
    const getSpecOrVersionValue = (
      type: "spec" | "version",
      value: string[]
    ) => {
      if (type === "spec") {
        return value ? value.slice(0, -1).join("-") : "";
      }
      if (type === "version") {
        return value ? value[value.length - 1] : "";
      }
    };

    if (!isAutoSave) {
      setIsCalculating(true);
      setIsSaveEnabled(false);
      setResetData(true);
    }

    setIsComponentDataChangePrimary([]);
    setIsComponentDataChangeSecondary([]);

    const fg_specSplitString = assessmentsData?.fg_spec?.split("-");
    const spec = getSpecOrVersionValue("spec", fg_specSplitString);
    const version = getSpecOrVersionValue("version", fg_specSplitString);
    // Update isEdited to true if _id is present
    const updatedPackagingLevel = packagingAllData.packaging_level.map(
      (level) => ({
        ...level,
        components: level.components.map((component) => {
          if (!component.isEdited && component._id && component.pc_nm !== "") {
            return {
              ...component,
              isEdited: true, // Set isEdited to true if _id exists and isEdited is false
            };
          }
          return component;
        }),
      })
    );

    const clearMaterial = (material) => {
      return material.map(({ _id, ...rest }) => ({
        ...rest,
      }));
    }
    // Remove 'id' from material objects and filter out empty PackagingComponentData objects
    const cleanedPackagingLevel = updatedPackagingLevel.map((level) => ({
      ...level,
      components: level.components
        .filter((component) => !isPackagingComponentDataEmpty(component)) // Remove empty components
        .map(({...component }) => ({
          ...component,
             // eslint-disable-next-line @typescript-eslint/no-unused-vars
            material: clearMaterial(component.material),
        })),
    }));

     const cleanedPackagingLevelForCalculation = updatedPackagingLevel.map((level) => ({
      ...level,
      components: level.components
        .filter((component) => !isPackagingComponentDataEmpty(component)) // Remove empty components
        .map(({...component }) => ({
          ...component,
             // eslint-disable-next-line @typescript-eslint/no-unused-vars
          material: clearMaterial(component.material),
          isCalculated: true  
        })),
    }));
    const {
      salesZone = "",
      productionZone = "",
      netContent = "",
      netContentUnit = "",
      useDose = "",
      useDoseUnit = "",
      consumablesUsed = "",
      productSegment = "",
      productSubSegment = "",
      rawMaterials = [{}],
      useScenario="",
    } = formulation || {};


    const newProductPostData = {
      productId: productData.productId,
      assessmentId: assessmentsData._id,
      assessmentType: assessmentsType,
      fg_spec: spec,
      fg_revision: version,
      sales_country: salesZone,
      production_country: productionZone,
      net_content: netContent,
      net_content_unit: netContentUnit,
      packaging_level: isAutoSave? cleanedPackagingLevel : cleanedPackagingLevelForCalculation, // Use cleaned data without empty components and 'id'
      packagingType: "Primary",
      useDose,
      useScenario,
      useDoseUnit,
      consumablesUsed,
      productSegment,
      productSubSegment,
      rawMaterials,
      isCalculating: !isAutoSave,
    };

    try {
      const response = await axios.post(
        `${ApiEndPointsURL}${ApiEndPoints.add_update_packaging}`,
        newProductPostData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        if (isAutoSave) {
           setPackagingSavedData(packagingAllData);
        }
        else if (!isAutoSave) {
        toast.success("Packaging Data Calculated");
        refetch()  
        setIsCalculating(false);
        setResetData(false);
        resultDataRefetch();
        }
     
      } else {
        toast.error(
          "Error occurred while submitting the Component details, please try again!",
          {
            type: "error",
            progressStyle: { background: "#e1aeae" },
            progressClassName: "toastProgressError",
            theme: "light",
            style: { color: "#FFFFFF", background: "#e1aeae" },

          }
        );
      if (isAutoSave) {
       setPackagingSavedData(packagingAllData);
      }
      if (!isAutoSave) {
          refetch()
          setIsCalculating(false);
          resultDataRefetch();
        }
      }
    } catch (ex) {
      toast.error(`${ex}`, {
        type: "error",
        progressStyle: { background: "#e1aeae" },
        progressClassName: "toastProgressError",
        theme: "light",
        style: { color: "#FFFFFF", background: "#e1aeae" },
      });
      if (!isAutoSave) { setIsCalculating(false) }
    }
  }, [packagingAllData, primaryData, secondaryData]);

  const extractBaseAndIndex = (str: string) => {
    const lastUnderscoreIndex = str.lastIndexOf("_");
    const fname = str.substring(0, lastUnderscoreIndex);
    const index = Number(str.substring(lastUnderscoreIndex + 1));
    return { fname, index };
  };

  const handelChangeRecycleStatus = (
    status: string,
    index: number,
    type: "Primary" | "Secondary",
    isAdd: boolean,
    isImportData: boolean
  ) => {
    const pcNmCheck =
      type === "Primary"
        ? primaryData[index].pc_nm !== ""
        : secondaryData[index].pc_nm !== "";
    if (!isAdd && pcNmCheck) {
      handleChangeClickValue(index, type);
    }
    setIsSaveEnabled(true);
    if (type === "Primary") {
      setPrimaryData((prevItems) =>
        prevItems.map((item, i) => {
          if (i === index) {
            const updatedItem = {
              ...item,
              recyclability_status: status.trim(),
              isEdited: item.isEdited ? item.isEdited : isImportData, // Set isEdited to true if _id exists, false if it doesn't
              isCalculated: false
            };
            updatedItem.isDataComplete = validateComponent(updatedItem); // Set validity flag
            return updatedItem;
          }
          return item;
        })
      );
    }

    if (type === "Secondary") {
      setSecondaryData((prevItems) =>
        prevItems.map((item, i) => {
          if (i === index) {
            const updatedItem = {
              ...item,
              recyclability_status: status.trim(),
              isEdited: item.isEdited ? item.isEdited : isImportData, // Set isEdited to true if _id exists, false if it doesn't
              isCalculated: false
            };
            updatedItem.isDataComplete = validateComponent(updatedItem); // Set validity flag
            return updatedItem;
          }
          return item;
        })
      );
    }
  };

  const validateComponent = (component: PackagingComponentData): boolean => {
    const requiredFields = [
      component.color,
      component.opacity,
      component.component_type,
      component.weight,
      component.opacifier,
      component.finishing_process,
      component.recyclability_status,
    ];

    const hasAllRequiredFields = requiredFields.every(
      (field) => field?.trim() !== ""
    );
    const totalMaterialPct = component.material.reduce(
      (total, material) => total + (Number(material.material_pct) || 0),
      0
    );
    const recyclabilityStatus = !!(
      component?.recyclability_status?.trim() === "Recycle Ready" ||
      component?.recyclability_status?.trim() === "Not Recycle Ready"
    );
    return (
      hasAllRequiredFields &&
      recyclabilityStatus &&
      (totalMaterialPct === 100 || totalMaterialPct === 100.0)
    );
  };

  const handelAllChanges = (
    name: string,
    value: string,
    type: "Primary" | "Secondary",
    isAdd: boolean,
    isImportData: boolean
  ) => {
    setIsSaveEnabled(true);
    const { fname, index } = extractBaseAndIndex(name);
    const pcNmCheck =
      type === "Primary"
        ? primaryData[index].pc_nm !== ""
        : secondaryData[index].pc_nm !== "";

    let isVlaueEmatyCheck = type === "Primary"
      ? !primaryData[index]?.[fname]?.trim()
      : !secondaryData[index]?.[fname]?.trim();
    
    if (fname === "weight") {
      isVlaueEmatyCheck = type === "Primary" ? !primaryData[index].fieldsExist?.weight : !secondaryData[index].fieldsExist?.weight
    }
    if (!isVlaueEmatyCheck) {
       if (!isAdd || pcNmCheck) {
      handleChangeClickValue(index, type);
    }
    }
   

    if (type === "Primary") {
      setPrimaryData((prevItems) =>
        prevItems.map((item, i) => {
          if (i === index) {
            const updatedItem = {
              ...item,
              [fname]: value.trim(),
              isEdited: item.isEdited ? item.isEdited : isImportData,// Set isEdited to true if _id exists, false if it doesn't
              isCalculated: false
            };
            updatedItem.isDataComplete = validateComponent(updatedItem); // Set validity flag
            return updatedItem;
          }
          return item;
        })
      );
    }

    if (type === "Secondary") {
      setSecondaryData((prevItems) =>
        prevItems.map((item, i) => {
          if (i === index) {
            const updatedItem = {
              ...item,
              [fname]: value.trim(),
              isEdited: item.isEdited ? item.isEdited : isImportData, // Set isEdited to true if _id exists, false if it doesn't
              isCalculated: false
            };
            updatedItem.isDataComplete = validateComponent(updatedItem); // Set validity flag
            return updatedItem;
          }
          return item;
        })
      );
    }
  };
  const handleChangeSelect = (
    e: SelectChangeEvent<string>,
    type: "Primary" | "Secondary",
    isAdd: boolean,
    isImportData: boolean
  ) => {
    const { name, value } = e.target;
    handelAllChanges(name, value, type, isAdd, isImportData);
    setIsSaveEnabled(true);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "Primary" | "Secondary",
    isAdd: boolean,
    isImportData: boolean
  ) => {
    setIsSaveEnabled(true);
    const { name, value } = e.target;
    handelAllChanges(name, value, type, isAdd, isImportData);
  };

  // When adding a new primary component, don't set isEdited to true
  const handleAddPrimary = () => {
    if (isPrimaryAddEnabled) {
      const primary: PackagingComponentData = {
        pc_nm: "",
        description: "",
        color: "",
        opacity: "",
        component_type: "",
        recyclability_status: "",
        weight: "",
        opacifier: "",
        stage: "",
        state: "",
        template: "",
        isEdited: false, // New components start with isEdited set to false
        isDataComplete: false,
        material: [],
        isCalculated:false,
      };
      setPrimaryData((prevData) => [...prevData, primary]);
      setIsPrimaryAddEnabled(false);
    }
  };

  // When adding a new secondary component, don't set isEdited to true
  const handleAddSecondary = () => {
    if (isSecondaryAddEnabled) {
      const secondary: PackagingComponentData = {
        pc_nm: "",
        description: "",
        color: "",
        opacity: "",
        component_type: "",
        recyclability_status: "",
        weight: "",
        opacifier: "",
        stage: "",
        state: "",
        template: "",
        isEdited: false, // New components start with isEdited set to false
        isDataComplete: false,
        isCalculated:false,
        material: [],
      };

      setSecondaryData((prevData) => [...prevData, secondary]);
      setIsSecondaryAddEnabled(false);
    }
  };
  const handelImportPackingData = useCallback(
    (
      data: PackagingComponentData,
      index: number,
      type: "Primary" | "Secondary"
    ) => {
      const updateData = (prevItems: PackagingComponentData[]) => {
        if (index < prevItems.length) {
          // If index is within bounds, update only specified fields while keeping other fields unchanged
          const updatedItems = prevItems.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                pc_nm: data.pc_nm ?? "",
                description: data.description ?? "",
                color: data.color ?? "",
                opacity: data.opacity ?? "",
                component_type: data.component_type ?? "",
                weight: data.weight ?? "",
                opacifier: data.opacifier ?? "",
                stage: data.stage ?? "",
                state: data.state ?? "",
                template: data.template ?? "",
                material: data.material.length > 0 ? data.material : [],
                finishing_process: data.finishing_process ?? "",
                fieldsExist:data.fieldsExist
              };
            }
            return item;
          });
          return updatedItems;
        } else {
          // If index is out of bounds, append the new data
          const newItems = [...prevItems, data];
          return newItems;
        }
      };

      setIsSaveEnabled(true);
      if (type === "Primary") {
        setPrimaryData(updateData);
        setIsComponentDataChangePrimary((prevState) =>
          prevState.filter((item) => item.index !== index+'p')
        );
      }
      if (type === "Secondary") {
        setSecondaryData(updateData);
        setIsComponentDataChangeSecondary((prevState) =>
          prevState.filter((item) => item.index !== index+'s')
        );
      }
    },
    []
  );

  const handelChangeTableData = (
    row: MaterialEntity[],
    index: number,
    type: "Primary" | "Secondary",
    isAdd: boolean
  ) => {

    if (type === "Primary" && primaryData?.length) {
      handleDataChange("Primary", row, index, isAdd);
    }

    if (type === "Secondary" && secondaryData?.length) {
      handleDataChange("Secondary", row, index, isAdd);
    }
  };

  // Helper function to handle data change for both Primary and Secondary types
  const handleDataChange = (
    type: "Primary" | "Secondary",
    row: MaterialEntity[],
    index: number,
    isAdd: boolean
  ) => {
    const data = type === "Primary" ? primaryData : secondaryData;
    const setData = type === "Primary" ? setPrimaryData : setSecondaryData;

    // Check if material has changed
    if (JSON.stringify(row) !== JSON.stringify(data[index]?.material)) {
      setIsSaveEnabled(true);
      const pcNmCheck = data[index].pc_nm !== "";
      // Handle click value condition based on isAdd and pcNmCheck
      if (!isAdd || pcNmCheck) {
        handleChangeClickValue(index, type);
      }
    }

    // Update data state
    setData((prevItems) =>
      prevItems.map((item, i) => {
        if (i === index) {
          const updatedItem = {
            ...item,
            material: row,
          };
          updatedItem.isDataComplete = validateComponent(updatedItem); // Set validity flag
          return updatedItem;
        }
        return item;
      })
    );
  };

  const setPcNmToEmpty = (index: number, type: "Secondary" | "Primary") => {
    if (type === "Primary") {
      setPrimaryData((prevData) => {
        const newData = [...prevData];
        if (newData[index]) {
          newData[index].pc_nm = "";
        }
        return newData;
      });
    }
    if (type === "Secondary") {
      setSecondaryData((prevData) => {
        const newData = [...prevData];
        if (newData[index]) {
          newData[index].pc_nm = "";
        }
        return newData;
      });
    }
  };
  const handleClickEditCancle = (
    index: number,
    type: "Secondary" | "Primary",
    componentData?: PackagingComponentData
  ) => {
    removeComponentDataChange(index, type);
    componentData
      ? updateComponentData(index, type, componentData)
      : restoreComponentData(index, type);
  };

  // Function to remove component data change status for the given index and type
  const removeComponentDataChange = (
    index: number,
    type: "Primary" | "Secondary"
  ) => {
    const trueItem = getComponentDataChangeItem(index, type);
    if (trueItem) {
      if (type === "Primary") {
        setIsComponentDataChangePrimary((prevState) =>
          prevState.filter((item) => item.index !== index+'p')
        );
      } else {
        setIsComponentDataChangeSecondary((prevState) =>
          prevState.filter((item) => item.index !== index+'s')
        );
      }
    }
  };

  // Function to fetch the item representing the change status of component data
  const getComponentDataChangeItem = (
    index: number,
    type: "Primary" | "Secondary"
  ) => {
    return type === "Primary"
      ? isComponentDataChangePrimary.find(
          (item) => item.index === index+'p' && item.value === true
        )
      : isComponentDataChangeSecondary.find(
          (item) => item.index === index+'s' && item.value === true
        );
  };

  // Function to update the component data (Primary or Secondary)
  const updateComponentData = (
    index: number,
    type: "Primary" | "Secondary",
    componentData: PackagingComponentData
  ) => {
    if (type === "Primary") {
      setPrimaryData((prevItems) =>
        updateDataAtIndex(prevItems, index, componentData)
      );
    } else {
      setSecondaryData((prevItems) =>
        updateDataAtIndex(prevItems, index, componentData)
      );
    }
  };

  // Helper function to update data at a specified index
  const updateDataAtIndex = (
    data: PackagingComponentData[], // Array of PackagingComponentData
    index: number, // Index to update
    componentData: PackagingComponentData // New component data to insert at the specified index
  ): PackagingComponentData[] => {
    const updatedData = [...data];
    updatedData[index] = componentData; // Update the item at the given index
    return updatedData; // Return the updated array
  };

  // Function to restore component data from saved packaging data or remove it
  const restoreComponentData = (
    index: number,
    type: "Primary" | "Secondary"
  ) => {
    const savedPackagingLevel = getSavedPackagingLevel(type);
    if (savedPackagingLevel) {
      const componentToRestore = savedPackagingLevel.components[index];
      if (componentToRestore) {
        restoreComponent(index, type, componentToRestore);
      } else {
        removeComponent(index, type);
      }
    } else {
      removeComponent(index, type);
    }
  };

  // Function to get saved packaging level based on type
  const getSavedPackagingLevel = (type: "Primary" | "Secondary") => {
    return packagingSavedData.packaging_level.find(
      (level) => level.packaging_level === type
    );
  };

  // Function to restore a component
  const restoreComponent = (
    index: number,
    type: "Primary" | "Secondary",
    componentToRestore: PackagingComponentData
  ) => {
    if (type === "Primary") {
      setPrimaryData((prevItems) =>
        updateDataAtIndex(prevItems, index, {
          ...componentToRestore,
          material: [...componentToRestore.material],
        })
      );
    } else {
      setSecondaryData((prevItems) =>
        updateDataAtIndex(prevItems, index, {
          ...componentToRestore,
          material: [...componentToRestore.material],
        })
      );
    }
  };

  // Function to remove the component from the data
  const removeComponent = (index: number, type: "Primary" | "Secondary") => {
    if (type === "Primary") {
      setPrimaryData((prevItems) => removeDataAtIndex(prevItems, index));
    } else {
      setSecondaryData((prevItems) => removeDataAtIndex(prevItems, index));
    }
  };

  const removeDataAtIndex = (
    data: PackagingComponentData[],
    index: number
  ): PackagingComponentData[] => {
    const updatedData = [...data];
    updatedData.splice(index, 1);
    return updatedData;
  };

  // Main function
  const handleClickCancelContinue = (
    index: number,
    type: "Secondary" | "Primary"
  ) => {
    const savedPackagingLevel = packagingSavedData.packaging_level.find(
      (level) => level.packaging_level === type
    );

    if (!savedPackagingLevel) {
      return removeData(type, index); // If savedPackagingLevel is not found, remove data
    }

    const componentToRestore = savedPackagingLevel.components[index];
    if (componentToRestore) {
      return restoreComponent(index, type, componentToRestore); // Restore the component if it exists
    }

    return removeData(type, index); // If component doesn't exist, remove data
  };

  // Function to handle data removal based on type
  const removeData = (type: "Secondary" | "Primary", index: number) => {
    if (type === "Primary") {
      setPrimaryData((prevData) => removeDataAtIndex(prevData, index));
    } else if (type === "Secondary") {
      setSecondaryData((prevData) => removeDataAtIndex(prevData, index));
    }
  };

  //useEffect section
  useEffect(() => {
    const isValidPrimary = hasNonEmptyValue(primaryData);
    const isValidSecondary = hasNonEmptyValue(secondaryData);
    setIsPrimaryAddEnabled(isValidPrimary);
    setIsSecondaryAddEnabled(isValidSecondary);
  }, [primaryData, secondaryData]);

  // update all changes/updated data
  useEffect(() => {
    const packagingLevelData: PackagingLevelData[] = [
      {
        packaging_level: "Primary",
        isrecyclable: false,
        recyclability_status: primaryRecycleStatus,
        productEvaluation: Number(productEvacuationValue),
        components: primaryData,
      },
      {
        packaging_level: "Secondary",
        isrecyclable: false,
        recyclability_status: secondaryRecycleStatus,
        productEvaluation: 0,
        components: secondaryData,
      },
    ];
    setPackagingAllData((prevData) => ({
      ...prevData,
      packaging_level: packagingLevelData,
    }));
    setCounterSecondary(secondaryData?.length);
    setCounterPrimary(primaryData?.length);
  }, [primaryData, secondaryData, productEvacuationValue]);


  // to get exiting data on 1st load/refresh 
  useEffect(() => {
    if (packagingData?.packaging_level) {
      setPackagingAllData({ packaging_level: packagingData.packaging_level });
      setPackagingSavedData({ packaging_level: packagingData.packaging_level });
    }
    // Initialize secondary data (existing data should not be marked as edited initially)
    if (secondaryPackaging) {
      setSecondaryData(secondaryPackaging.components);
      setSecondaryRecycleStatus(secondaryPackaging.recyclability_status)
    }
    // Initialize primary data (existing data should not be marked as edited initially)
    if (primaryPackaging) {
      setPrimaryData(primaryPackaging.components);
      setPrimaryRecycleStatus(primaryPackaging.recyclability_status);
      if (primaryPackaging.productEvaluation) {
        setProductEvacuationValue(
          primaryPackaging.productEvaluation.toString()
        );
      }
    }
  }, [packagingData, secondaryPackaging, primaryPackaging]);

  const areArraysMatching = (
    arr1: PackagingComponentData[],
    arr2: PackagingComponentData[]
  ): boolean => {
    // Ensure that the arrays are the same length or arr2 is longer
    if (arr1.length !== arr2.length) {
      if (arr2[arr2.length - 1].component_type !== "") {
        return false;
      } else {
        return true;
      }
    }
    // Compare each element in arr1 with arr2
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }
    // Check extra elements in arr2 for non-empty component_type

    return true;
  };
  useEffect(() => {
    //primaryPackaging === primaryData then not update setProductEvacuationValue
    if (primaryPackaging?.productEvaluation) {
      setProductEvacuationValue(primaryPackaging.productEvaluation.toString());
    }
    if (primaryData?.length > 0) {
      const matchArr = primaryPackaging?.components
        ? areArraysMatching(primaryPackaging?.components, primaryData)
        : false;
      if (!matchArr) {
        const hasPlumCompont = primaryData.every(
          (component) => component.component_type === ""
        );
        const hasPlumCompontType = primaryData.some(
          (component) => component.component_type === "Pump"
        );
        if (hasPlumCompont) {
          setProductEvacuationValue("");
        } else {
          hasPlumCompontType
            ? setProductEvacuationValue("80")
            : setProductEvacuationValue("90");
        }
      }
    } else {
      setProductEvacuationValue("");
    }
  }, [primaryData, primaryPackaging]);

  // Helper function to remove _id from components
const sanitizeData = (data: PackagingDataType): PackagingDataType => {
  return {
    packaging_level: data.packaging_level.map(level => {
        const { _id, ...rest } = level;
        return rest;
      }),
  };
};

  useEffect(() => {
    const sanitizedSavedData = sanitizeData(packagingSavedData);
    const sanitizedAllData = sanitizeData(packagingAllData);
    if (JSON.stringify(sanitizedSavedData) !== JSON.stringify(sanitizedAllData)) {
    const interval = setInterval(() => {
      handleSaveCalculatePacking(true)
      setIsOneTimeSaveDone(true)
        
    }, 10000);
    return () => clearInterval(interval);
  
  
  }  // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [packagingAllData, packagingSavedData])
  
  useEffect(() => {
    if (isCalculating) {
      setPrimaryData(prevItems => prevItems.map(item => ({ ...item, isCalculated: true })));
      setSecondaryData(prevItems => prevItems.map(item => ({ ...item, isCalculated: true })));
    }
  },[isCalculating])
  
  return {
    packagingAllData,
    primaryData,
    secondaryData,
    isSaveEnabled,
    productEvacuationValue,
    isPrimaryAddEnabled,
    isSecondaryAddEnabled,
    isCalculating,
    primaryRecycleStatus,
    secondaryRecycleStatus,
    counterPrimary,
    counterSecondary,
    resetData,
    packagingSavedData,
    isComponentDataChangePrimary,
    handelChangeTableData,
    handleChange,
    handleChangeSelect,
    handelImportPackingData,
    handleSavePacking,
    handelChangeRecycleStatus,
    setProductEvacuationValue,
    handleAddPrimary,
    handleAddSecondary,
    handleDeleteComponent,
    handleClickCancelContinue,
    setIsComponentDataChangePrimary,
    handleClickEditCancle,
    setPcNmToEmpty,
    isComponentDataChangeSecondary,
    setIsComponentDataChangeSecondary,
    setIsSaveEnabled,
    handleSaveCalculatePacking,
    isOneTimeSaveDone
  };
};

export default useConsumerPackaging;
