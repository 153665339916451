import React, { useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Box,
  TableSortLabel,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ExpandMore } from "@mui/icons-material";
import "../../../assets/css/packagingtable.css";
import "../../../assets/css/SIP.css";
import "../../../assets/css/ResultsPackingTableComponent.scss";
import ProgressBarWithLabel from "../../formulaAndConsumer/ProgressBarWithLabel";
import { ComponentDetail, Component } from "../../../structures/packaging";
import { ResultDataContext } from "../../../contexts/resultData/ResultDataContext";
import { CURRENT_TAB } from "../../../constants/String.constants";
import { sortData, truncate } from "../../../helper/GenericFunctions";
import { SortableTableHeaderProps } from "../sustainablePackaging/structure";

interface IResultPackaging {
  currentTab: string;
}

const SortableTableHeader: React.FC<SortableTableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
  column,
  className = "",
}) => {
  const { id, label, align = "left", colSpan = 1, rowSpan = 1, width } = column;

  const handleSort = () => {
    onRequestSort(id);
  };

  return (
    <TableCell
      align={align}
      colSpan={colSpan}
      rowSpan={rowSpan}
      style={{ border: "1px solid #E4E7EC" }}
      className={className}
      sx={{
        position: "sticky",
        width: width || "auto", // Set width if provided, fallback to 'auto'
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box>
          <Typography variant="body1" className="table-header-font">
            {label}
          </Typography>
        </Box>
        <TableSortLabel
          active={orderBy === id}
          direction={order}
          onClick={handleSort}
          sx={{
            "&.Mui-active": {
              color: "theme.palette.primary.main",
              fontWeight: "bold",
            },
            "& .MuiTableSortLabel-icon": {
              opacity: 0.1,
              transition: "opacity 0.3s, font-weight 0.3s",
            },
            "&:not(.Mui-active)": {
              color: "theme.palette.text.secondary",
              fontWeight: "normal",
            },
            "&:hover .MuiTableSortLabel-icon": {
              opacity: 1,
              fontWeight: "bold",
            },
          }}
        ></TableSortLabel>
      </Box>
    </TableCell>
  );
};

export const ResultsPackagingTable: React.FC<IResultPackaging> = (props) => {
  const { productEnvironmentalFootprintData, currentTab } =
    useContext(ResultDataContext);
  const staticData =
    productEnvironmentalFootprintData?.packaging?.consumerPackaging;
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});
  const [outerSort, setOuterSort] = useState<{
    orderBy: keyof Component;
    order: "asc" | "desc";
  }>({
    order: "asc",
    orderBy: "componentName",
  });
  const [innerSort, setInnerSort] = useState<{
    orderBy: keyof ComponentDetail;
    order: "asc" | "desc";
  }>({
    order: "asc",
    orderBy: "materialName",
  });

  const toggleRow = (id: number) => {
    setOpenRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleOuterSort = (property: keyof Component) => {
    if (
      property === "baseLineComponentWeight" ||
      property === "myProductComponentWeight"
    ) {
      setOpenRows({});
    }
    const isAsc = outerSort.orderBy === property && outerSort.order === "asc";
    setOuterSort({ orderBy: property, order: isAsc ? "desc" : "asc" });
  };

  const handleInnerSort = (property: keyof ComponentDetail) => {
    const isAsc = innerSort.orderBy === property && innerSort.order === "asc";
    setInnerSort({ orderBy: property, order: isAsc ? "desc" : "asc" });
  };

  const handleSortingRequest = (
    property: keyof Component | keyof ComponentDetail
  ) => {
    if (
      property === "componentName" ||
      property === "baseLineComponentWeight" ||
      property === "myProductComponentWeight"
    ) {
      handleOuterSort(property);
    }
    if (
      property === "materialName" ||
      property === "myProductEnvironmentalFootprint" ||
      property==="convertingProcess" ||
      property === "baselineWeight" ||
      property === "materialType" ||
      property === "baselineEnvironmentalFootprint" ||
      property === "myProductWeight"
    ) {
      handleInnerSort(property);
    }
  };

  const sortedOuterData = sortData(
    [...staticData],
    outerSort.orderBy,
    outerSort.order
  );

  // Function to get color based on value
  const getColorByValue = (value: number) => {
    if (value <= 30) return "#CFDDEC";
    if (value <= 60) return "#3F7AB4";
    return "#0A316B";
  };

  const getComponentTypeColumnName = (): string => {
    return currentTab === CURRENT_TAB.PRODUCT_ENVIRONMENTAL_FOOTPRINT
      ? "Component Type"
      : "Component Name";
  };

  const getMaterialTypeColumnName = () => {
    return currentTab === CURRENT_TAB.PRODUCT_ENVIRONMENTAL_FOOTPRINT
      ? "Material Type"
      : "PCR/PIR/ Virgin";
  };

  return (
    <div className="table-wrapper-resultspacking">
      <Grid container>
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            className="table-container-results"
            sx={{ overflow: "auto" }}
          >
            <Table stickyHeader>
              <TableHead
                sx={{
                  position: "sticky",
                  zIndex: 1,
                  fontWeight: "bold",

                  "& th": {
                    fontWeight: "bold",
                    backgroundColor: "#f5f5f5",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    padding: "8px 1px 8px 8px !important",
                  },
                  "& .MuiTableSortLabel-root:not(.Mui-active)": {
                    fontWeight: "bold",
                  },
                }}
              >
                <TableRow
                  className="table-first-heading-row"
                  sx={{
                    position: "sticky",
                    top: "0px", // Adjust this value according to the height of the previous row
                    "& th": {
                      whiteSpace: "normal", // Allow normal white space
                      wordWrap: "break-word", // Allow words to break
                      height: "10px !important",
                      padding: "8px 1px 8px 18px !important",
                    },
                  }}
                >
                  <SortableTableHeader
                    order={outerSort.order}
                    orderBy={outerSort.orderBy}
                    onRequestSort={handleSortingRequest}
                    className="table-cell0"
                    column={{
                      id: "componentName",
                      label: getComponentTypeColumnName(),
                      rowSpan: 2,
                      width: "120px",
                    }}
                  />
                  <SortableTableHeader
                    order={innerSort.order}
                    orderBy={innerSort.orderBy}
                    onRequestSort={handleSortingRequest}
                    className="table-cell1"
                    column={{
                      id: "materialName",
                      label: "Material Name",
                      rowSpan: 2,
                      width: "100px",
                    }}
                  />
                  <SortableTableHeader
                    order={innerSort.order}
                    orderBy={innerSort.orderBy}
                    onRequestSort={handleSortingRequest}
                    className="table-cell2"
                    column={{
                      id: "materialType",
                      label: getMaterialTypeColumnName(),
                      rowSpan: 2,
                      width: "100px",
                    }}
                  />
                  <SortableTableHeader
                    order={innerSort.order}
                    orderBy={innerSort.orderBy}
                    onRequestSort={handleSortingRequest}
                    className="table-cell3"
                    column={{
                      id: "convertingProcess",
                      label: "Converting Process",
                      rowSpan: 2,
                      width: "100px",
                    }}
                  />
                  <TableCell
                    className="table-cell4"
                    colSpan={2}
                    align="center"
                    style={{ border: "1px solid #E4E7EC" }}
                    sx={{
                      position: "sticky",
                      border: "1px solid #E4E7EC",
                    }}
                  >
                    Baseline Product
                  </TableCell>
                  <TableCell
                    className="table-cell5"
                    colSpan={2}
                    align="center"
                    style={{ border: "1px solid #E4E7EC" }}
                    sx={{
                      position: "static",
                      border: "1px solid #E4E7EC",
                    }}
                  >
                    My Product
                  </TableCell>
                </TableRow>
                <TableRow
                  className="table-second-heading-row"
                  sx={{
                    position: "sticky",
                    top: "36px",
                    "& th": {
                      whiteSpace: "normal", // Allow normal white space
                      wordWrap: "break-word", // Allow words to break
                    },
                  }}
                >
                  <SortableTableHeader
                    order={outerSort.order}
                    orderBy={outerSort.orderBy}
                    onRequestSort={handleSortingRequest}
                    column={{
                      id: "baseLineComponentWeight",
                      label: "Weight (g)",
                      align: "center",
                    }}
                  />
                  <SortableTableHeader
                    order={innerSort.order}
                    orderBy={innerSort.orderBy}
                    onRequestSort={handleSortingRequest}
                    column={{
                      id: "baselineEnvironmentalFootprint",
                      label: (
                        <span style={{ whiteSpace: "pre-line" }}>
                          {props.currentTab ===
                          "PRODUCT_ENVIRONMENTAL_FOOTPRINT"
                            ? "Product Environmental Footprint (points)"
                            : " Carbon Footprint \n(g CO2 eq.)"}
                          <Tooltip title="Carbon footprint in g CO2 eq.">
                            <IconButton
                              size="small"
                              className="info-icon"
                              sx={{ ml: 1 }}
                            >
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </span>
                      ),
                    }}
                  />
                  <SortableTableHeader
                    order={outerSort.order}
                    orderBy={outerSort.orderBy}
                    onRequestSort={handleSortingRequest}
                    column={{
                      id: "myProductComponentWeight",
                      label: "Weight (g)",
                      align: "center",
                    }}
                  />
                  <SortableTableHeader
                    order={innerSort.order}
                    orderBy={innerSort.orderBy}
                    onRequestSort={handleSortingRequest}
                    column={{
                      id: "myProductEnvironmentalFootprint",
                      label: (
                        <span style={{ whiteSpace: "pre-line" }}>
                          {props.currentTab ===
                          "PRODUCT_ENVIRONMENTAL_FOOTPRINT"
                            ? "Product Environmental Footprint (points)"
                            : " Carbon Footprint \n(g CO2 eq.)"}
                          <Tooltip title="Carbon footprint in g CO2 eq.">
                            <IconButton
                              size="small"
                              className="info-icon"
                              sx={{ ml: 1 }}
                            >
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </span>
                      ),
                    }}
                  />
                </TableRow>
              </TableHead>
              <TableBody className="table-body-results">
                {sortedOuterData.map((row, index) => (
                  <React.Fragment key={index + 1}>
                    <TableRow
                      className= "consumer-component-table-cell"
                      hover
                      sx={{
                        cursor: "pointer",
                        "& td": {
                          border: "1px solid #e0e0e0", // Add border to each cell
                          padding: "10px 18px !important",
                        },
                      }}
                    >
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography
                            variant="body1"
                            sx={{ flexGrow: 1, fontWeight: "bold" }}
                            className= "consumer-component-table-cell-typography"
                          >
                            {row.componentName}
                          </Typography>
                          <IconButton
                            size="small"
                            aria-label="expand row"
                            sx={{ ml: 1 }}
                            onClick={() => toggleRow(index)}
                          >
                            {openRows[index] ? (
                              <ExpandMore />
                            ) : (
                              <ChevronRight />
                            )}
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell sx={{ textAlign: "center", width: "137px" }}>
                        {(row.baseLineComponentWeight
                          ? row.baseLineComponentWeight?.toFixed(2)
                          : ""
                        )}
                      </TableCell>
                      <TableCell width={"260px"}>
                      {Boolean(row.baseLineComponentWeight) && (
                        <ProgressBarWithLabel
                          value={Number("0")}
                          color={getColorByValue(Number("0"))}
                          label={`${Number("0")}`}
                        />
                      )}

                      </TableCell>
                      <TableCell sx={{ textAlign: "center", width: "137px" }}>
                        {(row.myProductComponentWeight
                          ? row.myProductComponentWeight?.toFixed(2)
                          : ""
                        )}
                      </TableCell>
                      <TableCell width={"260px"}>
                      {Boolean(row.myProductComponentWeight) && (
  <ProgressBarWithLabel
    value={Number("0")}
    color={getColorByValue(Number("0"))}
    label={`${Number("0")}`}
  />
)}
                      </TableCell>
                    </TableRow>
                    {openRows[index] && (
                      <>
                        {sortData(
                          [...row.details],
                          innerSort.orderBy,
                          innerSort.order
                        ).map((detail, index) => (
                          <TableRow
                            key={index + 1}
                            sx={{
                              "& td": {
                                backgroundColor: "#f5f5f5",
                                border: "1px solid #e0e0e0", // Add border to each cell
                                padding: "10px 18px !important",
                              },
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell>
                              {truncate(detail.materialName, 14)}
                            </TableCell>
                            <TableCell>{detail.materialType}</TableCell>
                            <TableCell>{detail.convertingProcess}</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {detail.baselineWeight
                                ? parseFloat(detail.baselineWeight).toFixed(2)
                                : ""}
                            </TableCell>
                            <TableCell>
                              {detail.baselineWeight  && (
                                <ProgressBarWithLabel
                                  value={Number(
                                    detail.baselineEnvironmentalFootprint || "0"
                                  )}
                                  color={getColorByValue(
                                    Number(
                                      detail.baselineEnvironmentalFootprint ||
                                        "0"
                                    )
                                  )}
                                  label={`${Number(
                                    detail.baselineEnvironmentalFootprint || "0"
                                  )}%`}
                                />
                              )}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {detail.myProductWeight
                                ? parseFloat(detail.myProductWeight).toFixed(2)
                                : ""}
                            </TableCell>

                            <TableCell>
                              {detail.myProductWeight && (
                                <ProgressBarWithLabel
                                  value={Number(
                                    detail.myProductEnvironmentalFootprint ||
                                      "0"
                                  )}
                                  color={getColorByValue(
                                    Number(
                                      detail.myProductEnvironmentalFootprint ||
                                        "0"
                                    )
                                  )}
                                  label={`${Number(
                                    detail.myProductEnvironmentalFootprint ||
                                      "0"
                                  )}%`}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};
