import React, { useState, useContext, useEffect, useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import complete from "../../assets/images/complete.svg";
import incomplete from "../../assets/images/incomplete.svg";
import logo from "../../assets/images/Sustainable-Innovation-Tool-Logo-With-Endorsement-Line.svg";
import makeStyles from "@mui/styles/makeStyles";
import { Avatar, Divider, Box } from "@mui/material";
import "../../assets/css/tooltip.scss";
import { TabPanel } from "../../constants/Formula.constant";
import { ConsumerPackaging } from "./../consumer-packaging-tab";
import { ProductDataContext } from "../../contexts/productData/ProductDataContext";
import DialsResultProductAssessment from "../common/DialsResultProductAssessment";
import "react-toastify/dist/ReactToastify.css";
import EditAssessmentTitle from "../breadcrumb/EditAssessmentTitle.component";
import FormulaAndConsumer from "../formulation/formulation-tab/FormulaAndConsumer";
import { CommonBreadcrumb } from "../breadcrumb/CommonBreadcrumb.component";
import { ConsumerPackagingProvider } from "./../consumer-packaging-tab/ConsumerPackagingContext";
import { errorMsgDialsWithoutPartialData, checkAllCondiationForDials } from "../../helper/GenericFunctions";
import { ConsumerPackagingRef } from "../consumer-packaging-tab/ConsumerPackaging.component";
import { AutoSaveContext } from "../../contexts/autoSaveContext/AutoSaveContext";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#00B097",
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "black",
    },
  },
});

const ProductAssessmentDetail: React.FC = () => {
  const classes = useStyles();
  const [breadcrumbData, setBreadcrumbData] = useState({
    productID: "",
    productName: "",
    experimentalID: "",
    experimentalName: "",
  });
  const { setTabSwitched, changedFields } = useContext(AutoSaveContext);
  const { productData, assessmentsData, isBaselinePresent, assessmentsType,isBaselineDataComplete } =
    useContext(ProductDataContext);
  const assessmentType = assessmentsType;
  const [value, setValue] = React.useState(0);
  const [isDialsWithoutDataShow, setIsDialsWithoutDataShow] = useState("no");
  const [dialsWithoutDataShowMsg, setDialsWithoutDataShowMsg] = useState("");
  const consumerPackagingRef = useRef<ConsumerPackagingRef>(null);
  useEffect(() => {

    setBreadcrumbData(getBreadcrumbData(productData, assessmentsData));
   
    const shouldShowResultDials = () => checkAllCondiationForDials(
   
      isBaselineDataComplete,
     
      isBaselinePresent,
     
      assessmentsData?.isFormulationDataCompleted,
     
      assessmentsData?.isPackagingDataCompleted,
     
      assessmentsData?.isFormulationCalculated,
     
      assessmentsData?.isPackagingCalculated
     
     );
     
     
     
     const getDialsMessage = () => errorMsgDialsWithoutPartialData(
     
      isBaselineDataComplete ? "yes" : "no",
     
      isBaselinePresent ? "yes" : "no",
     
      assessmentsData?.isFormulationDataCompleted ? "yes" : "no",
     
      assessmentsData?.isPackagingDataCompleted ? "yes" : "no",
     
      assessmentsData?.isFormulationCalculated ? "yes" : "no",
     
      assessmentsData?.isPackagingCalculated ? "yes" : "no"
     
     );
     
   
    if (!assessmentsData) return;
   
   
   
    if (shouldShowResultDials()) {
   
     setIsDialsWithoutDataShow("no");
   
     setDialsWithoutDataShowMsg("");
   
     return;
   
    }   
   
    const message = getDialsMessage();
   
    setIsDialsWithoutDataShow(message === "no" ? "no" : "yes");
   
    setDialsWithoutDataShowMsg(message === "no" ? "" : message);
   
     
   
   }, [productData, assessmentsData, isBaselinePresent, isBaselineDataComplete]);
   
   // **Helper Functions**
   
   const getBreadcrumbData = (productData, assessmentsData) => ({
   
    productID: productData?.productId,
   
    productName: productData?.productName,
   
    experimentalID: assessmentsData?._id,
   
    experimentalName: assessmentsData?.name,
   
   });
   

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0 && consumerPackagingRef.current) {
      consumerPackagingRef.current.packagingDataSave();
    }
    if (changedFields.length > 0) {
      setTabSwitched(true);
    }
  };

  return (
     <ConsumerPackagingProvider>
    
      <img
        src={logo}
        alt="Sustainable Innovation Profiler"
        style={{ width: "359.27px", height:"35px",  }}
      ></img>
      <Divider></Divider>

      <CommonBreadcrumb
        productID={breadcrumbData?.productID}
        experimentalID={breadcrumbData?.experimentalID}
        productName={breadcrumbData?.productName}
        experimentalName={breadcrumbData?.experimentalName}
        path={window.location.pathname}
      />

      {/* START CODE - PRODUCT ASSESSMENT EDIT TITLE */}
      <EditAssessmentTitle />
      {/* END CODE - PRODUCT ASSESSMENT EDIT TITLE */}

      {/* START CODE - DISPLAY DIALS AND CHART  - PRODUCT ASSESSMENT */}
      {(assessmentType === "final" || assessmentType === "experimental") && (
        <DialsResultProductAssessment
          page="product-assessment"
          dials_without_data_show={isDialsWithoutDataShow}
          dials_without_data_show_msg={dialsWithoutDataShowMsg}
        />
      )}
      {/* END CODE - DISPLAY DIALS AND CHART - PRODUCT ASSESSMENT */}

      {/* Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon position"
          className={classes.tabs}
          style={{ height: "61px", padding: "0px", borderRadius: "0px" }}
          sx={{
            "& .jss2": {
              padding: "0px",
              borderRadius: "0px",
            },
          }}
        >
          <Tab
            value={0}
            icon={
              assessmentsData?.isFormulationDataCompleted ? (
                <Avatar
                  style={{ height: "18px", width: "18px", marginLeft: "12px" }}
                  src={complete}
                />
              ) : (
                <Avatar
                  style={{ height: "18px", width: "18px", marginLeft: "12px" }}
                  src={incomplete}
                />
              )
            }
            sx={{
              fontWeight: "700",
              fontSize: "19.2px",
              fontFamily: "kenvue-sans-regular",
              textTransform: "none",
              paddingLeft: "0px",
              paddingRight: "0px",
              height: "41px",
            }}
            iconPosition="end"
            label="Formulation"
            style={{ marginRight: "36px" }}
          />
          <Tab
            value={1}
            icon={
              assessmentsData.isPackagingDataCompleted ? (
                <Avatar
                  style={{ height: "18px", width: "18px", marginLeft: "12px" }}
                  src={complete}
                />
              ) : (
                <Avatar
                  style={{ height: "18px", width: "18px", marginLeft: "12px" }}
                  src={incomplete}
                />
              )
            }
            sx={{
              fontWeight: "700",
              fontSize: "19.2px",
              fontFamily: "kenvue-sans-regular",
              textTransform: "none",
              paddingLeft: "0px",
              paddingRight: "0px",
              height: "41px",
            }}
            iconPosition="end"
            label="Consumer Packaging"
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FormulaAndConsumer />
      </TabPanel>

      <TabPanel value={value} index={1}>
       
          <ConsumerPackaging ref={consumerPackagingRef} />
       
      </TabPanel>
      </ConsumerPackagingProvider>
  );
};
  
  export default ProductAssessmentDetail;
