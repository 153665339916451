export const RECYCLE_READY_PARTONE: string =
  "Kenvue prioritizes development of recyclable and recycle-ready solutions. ";
export const RECYCLE_READY_SECONDPART: string =
  " of creating more environmentally friendly packaging by designing materials that can easily be recycled. This then ensures we are making it that much easier for our consumers to also make the right choice to recycle their packaging and reduces the risk that our products end up as waste.";
export const MATERIAL_EFFICIENCY_PARTONE: string =
  "Optimize materials in packaging design must deliver the "
export const MATERIAL_EFFICIENCY_SECONDPART: string =
  " It can also help to reduce the burden of regulatory fees and taxes such as local country EPR fees."
export const RECYCLABILITY_DISRUPTORS: string =
  " Recyclability disruptors are packaging materials or designs that are either harmful to our environment or prevent circularity (e.g., PVC). The full list, based on a compilation of multiple internal and external resources, can be viewed here. These materials and colorants are viewed as contaminants to recyclers in most recovery facilities, so we must actively design away from them."
export const TOTAL_SCORE_DESCRIPTION: string =
  " The Sustainable Packaging score promotes the design of sustainable packaging experiences that delight <br/> consumers everyday. It is based on four key pillars that drive Kenvue's global sustainability strategy:"
export const TOTAL_SCORE_PCR_CONTENT: string =
  "Measures the proportion of post consumer recycled material included in the packaging."
export const TOTAL_SCORE_MATERIAL_EFFICIENCY: string =
  "Measures the efficiency of the packaging structure by calculating the packaging weight per functional unit (dose)."
export const TOTAL_SCORE_RECYCLE_READY: string =
  "Measures the proportion of packaging that has been designed to be 'recycle ready'."
export const TOTAL_SCORE_RECYCLABILITY_DISRUPTORS: string =
  "Flags the presence of packaging materials that are defined as 'recyclability disruptors'."
export const RECYCLABILITY_DISRUPTORS_GOOD_JOB_CONTENT: string =
  "There are no recyclability disruptors present in the packaging."
export const RECYCLABILITY_DISRUPTORS_WATCH_OUT_CONTENT: string =
  "There are one or more recyclability disruptors present in the packaging. This packaging will automatically be given a Sustainable Packaging score of \"Very Poor\". "
export const TOTAL_PRODUCT_PEF: string =
  "The Product Environmental Footprint metric is a single score combining 16 environmental impacts, for example water use, pollution, and land use, across different life cycle stages, from the production of materials, through manufacturing, to use and disposal. At Kenvue, we are looking for opportunities to reduce the environmental impact of our products without compromising on performance, which means designing products with a lower Product Environmental Footprint score."
export const TOTAL_PRODUCT_PEF_CONTENT: string =
  "As a first step, it is helpful to identify which stages of a product’s life cycle have the highest environmental impact as this will tell us where to focus."

export const FORMULATION_PEF: string = "The Product Environmental Footprint metric is a single score combining 16 environmental impacts, for example water use, pollution, and land use, across different life cycle stages, from the production of materials, through manufacturing, to use and disposal. At Kenvue, we are looking for opportunities to reduce the environmental impact of our products without compromising on performance, which means designing products with a lower Product Environmental Footprint score."
export const FORMULATION_PEF_CONTENT: string = "To reduce the Product Environmental Footprint of our formulations, we need to understand which Raw Materials have the highest impact. Could these materials be reduced or replaced with more sustainable alternatives ? "

export const CONSUMER_PACKAGING_PEF: string = "The Product Environmental Footprint metric is a single score combining 16 environmental impacts, for example water use, pollution, and land use, across different life cycle stages, from the production of materials, through manufacturing, to use and disposal. At Kenvue, we are looking for opportunities to reduce the environmental impact of our products without compromising on performance, which means designing products with a lower Product Environmental Footprint score."
export const CONSUMER_PACKAGING_PEF_CONTENT: string = "To reduce the Product Environmental Footprint of our packaging, we need to understand which materials have the highest impact. Could these materials be reduced or replaced with more sustainable alternatives ? "

export const TOTAL_PRODUCT_CF: string = "The Carbon Footprint metric measures the greenhouse gas emissions created across different life cycle stages, from the production of materials, through manufacturing, to use and disposal. At Kenvue, we have made a commitment to reduce our carbon footprint. Innovating to design products with a lower carbon footprint without compromising on performance is key lever to achieve this."
export const TOTAL_PRODUCT_CF_CONTENT: string = "As a first step, it is helpful to understand which stage or stages of a product’s life cycle have the highest carbon footprint as this will tell us where to focus."

export const FORMULATION_CF: string = "The Carbon Footprint metric measures the greenhouse gas emissions created across different life cycle stages, from the production of materials, through manufacturing, to use and disposal. At Kenvue, we have made a commitment to reduce our carbon footprint. Innovating to design products with a lower carbon footprint without compromising on performance is key lever to achieve this."
export const FORMULATION_CF_CONTENT: string = "To reduce the Carbon Footprint of our formulations, we need to understand which Raw Materials have the highest impact. Could these materials be reduced or replaced with lower - carbon alternatives ? "

export const CONSUMER_PACKAGING_CF: string = "The Carbon Footprint metric measures the greenhouse gas emissions created across different life cycle stages, from the production of materials, through manufacturing, to use and disposal. At Kenvue, we have made a commitment to reduce our carbon footprint. Innovating to design products with a lower carbon footprint without compromising on performance is key lever to achieve this."
export const CONSUMER_PACKAGING_CF_CONTENT: string = "To reduce the Carbon Footprint of our packaging, we need to understand which materials have the highest impact. Could these materials be reduced or replaced with lower-carbon alternatives?"