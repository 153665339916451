/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useContext, useMemo} from "react";
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Tooltipcommon from "../../controls/Tooltip";
import "../../assets/css/SIP.css";
import { ProductDataContext } from "../../contexts/productData/ProductDataContext";
import { CheckCRUDAccess } from "../../helper/GenericFunctions";

interface FormFieldProps {
  validateDropdownvalues: boolean;
  label: string;
  required?: boolean;
  tooltipContent: string;
  direction: string;
  value: string;
  onChange: (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement>) => void;
  options?: string[] | null;
  name?: string;
  isEdited: boolean;
  isRecyclabilityField?:boolean;
}
 
const FormField: React.FC<FormFieldProps> = ({
  validateDropdownvalues,
  label,
  required = false,
  tooltipContent,
  direction,
  value,
  onChange,
  options = [],
  name,
  isEdited,
  isRecyclabilityField
}) => {
  const { assessmentsType , usersData} = useContext(ProductDataContext);
 
  const isValuePresent = options?.includes(value) ?? false;
  // Disable the field if assessmentsType is 'baseline' or 'final' and isEdited is true
  const isFieldDisabled = useMemo(() => {
    if (CheckCRUDAccess(usersData, "consumer_packaging") === 0) {
      return true;
    }
    return (assessmentsType === "baseline" || assessmentsType === "final") && isEdited;
  },[assessmentsType,isEdited])

  // Apply gray color when the field is disabled
  const labelStyle = { color: "black" };
  const textStyle = { color: "black" };

    // Determine if there is an error based on the value
const hasError = validateDropdownvalues? (!value || !isValuePresent):false;
const selectwidth = (label === "Recyclability Disruptors" || label === "Finishing Process") ? "200px" : "120px";

 return (
<div style={{
    padding: hasError ? "10px" : "0px",
    marginTop: hasError ? "-8px" : "0px",
     backgroundColor: hasError ? "#fbd2d280" : "transparent", // Light red background on error
    borderRadius: hasError ? "20px" : "0px", // Optional: Add rounded corners for better appearance
    paddingBottom: hasError ? "7px" : "0px",
    }}>
  <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
                      fontWeight="700"
                      fontSize={"13.33px"}
                      sx={{ fontFamily: "kenvue-sans", fontWeight: "700", width:"max-content", ...labelStyle }}
                      variant="subtitle1"
            >
                      {label}
            </Typography>
          {required && (
              <Typography
                          fontWeight="400"
                          fontSize={"13.33px"}
                          sx={{ fontFamily: "kenvue-sans-regular" }}
                          variant="subtitle1"
                          color={"red"}
              >
                          *
              </Typography>
       )}
       {tooltipContent !== "" &&
         <Tooltipcommon content={tooltipContent} direction={direction}>
           <InfoIcon style={textStyle} />
         </Tooltipcommon>
       }
  </div>
  <div style={{ }}>
  <FormControl sx={{ border: "none", "& fieldset": { border: "none" } }}>
  <Select
                value={value}
                name={name}
                onChange={onChange}
                displayEmpty
                disabled={isFieldDisabled} // Disable based on the condition
                className="disabledfield"
                sx={{
                  "& .MuiSelect-select": {
                    padding: hasError ? "12.5px 0px" : "14.5px 0px",
                    backgroundColor: "none",
                    ...textStyle,
                  },
                }}
                style={{
                  width: isRecyclabilityField? "auto" : selectwidth,
                  fontFamily: "kenvue-sans-regular",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "3px",
                  ...textStyle, // Apply gray text style when disabled
                }}
  >
        <MenuItem disabled value=""> Select</MenuItem>
        {options?.map((option, i) => (
            <MenuItem key={i+1} value={option} style={textStyle}>{option}</MenuItem>
          ))}
     </Select>

  </FormControl>
  </div>
  
  {isRecyclabilityField && <p>
        <i>{"Please review Kenvue's "}</i>
        <a
          href="https://kenvue.sharepoint.com/teams/rndsustainability-kv/Sustainable%20Packaging/FINAL%20DFR_20thMay2024.pdf?web=1"
          target="_blank"
          style={{
              color: "black",
              fontWeight: "700",
              whiteSpace: "pre-line",
              textUnderlineOffset:"4px"
            }}
        >{"Design for Recyclability Guidebook"}</a><i>{" to determine component recyclability."}</i>
    </p>}
</div>
  );
};
 
export default FormField;