import React, { useContext, useEffect } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import signout from "../../assets/images/signout.png";
import full_signout from "../../assets/images/Full.png";
import kenvue_full_logo from "../../assets/images/kenvue_full_logo.png";
import Kenvue_Logo from "../../assets/images/Kenvue_Logo.png";

import homes_w from "../../assets/images/homes_w.png";
import home from "../../assets/images/home.png";
import homegreen from "../../assets/images/homegreen.png";
import openBar from "../../assets/images/open.png";
import close from "../../assets/images/close.png";
import helps from "../../assets/images/helps.png";
import help from "../../assets/images/help.png";
import allproduct from "../../assets/images/allproduct.png";

import allproductgreenopen from "../../assets/images/allproductgreenopen.png";
import allproducts from "../../assets/images/allproducts.png";
import { useLocation, useNavigate } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { SidebarContext } from "../../contexts/sidebarData/SidebarStateContext";
import { SideBarProps } from "../breadcrumb/types";
import { AutoSaveContext } from "../../contexts/autoSaveContext/AutoSaveContext";

export const BootstrapTooltipSideBar = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      placement="bottom-start"
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#00b097",
    marginLeft: "-20px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#00b097",
    border: "2px solid #FFFFFF",
    boxShadow: theme.shadows[1],
    fontFamily: "kenvue-sans",
    fontWeight: "700",
  },
}));

const drawerWidth = 180;

const openedMixin = (): CSSObject => ({
  width: drawerWidth,
  overflow: "hidden",
  background: "black",
  borderRadius: "0px 35px 0px 0px",
  // position:"sticky"
});

const closedMixin = (theme: Theme): CSSObject => ({
  overflow: "hidden",
  borderRadius: "0px 35px 0px 0px",
  background: "black",
  width: `calc(${theme.spacing(7)} + 0px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7.5)} + 0.0px)`,
  },
  // position:"sticky"
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(),
    "& .MuiDrawer-paper": openedMixin(),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const getHomeImage = (open, homeIcon) => {
  if (open) {
    return <img src={home} alt="" />;
  } else if (!homeIcon) {
    return <img src={homes_w} alt="" />;
  } else {
    return <img src={home} alt="" />;
  }
};

const getWidth = (open) => (open ? 1 : "auto");
const getOpacity = (open) => (open ? 0 : 1);
const renderLogo = (open) => {
  return !open ? (
    <img src={Kenvue_Logo} style={{ marginLeft: "25%", marginTop: "20px" }} alt="" />
  ) : (
    <img style={{ marginTop: "20px", marginLeft: "0px" }} src={kenvue_full_logo} alt="" />
  );
};
const getSignOutIcon = (open) =>
  !open ? (
    <img src={signout} style={{ marginLeft: "15px" }} alt="" />
  ) : (
    <img src={full_signout} alt="" />
  );

export default function SideBarAction(props: SideBarProps) {
  const navigate = useNavigate();
  const useLocations = useLocation();

  const { setTabSwitched, changedFields } = useContext(AutoSaveContext);
  const { setCurrentSection, currentSection } = useContext(SidebarContext);

  const [open, setOpen] = React.useState(false);

  const [homeIcon, setHomeIcon] = React.useState<boolean>(true);
  const [allProdIcon, setAllProdIcon] = React.useState<boolean>(false);
  const handleClickArrow = () => {
    setOpen((prevStep) => !prevStep);
  };

  useEffect(() => {
    if (currentSection === "home") {
      setHomeIcon(true);
      setAllProdIcon(false);
    } else {
      setHomeIcon(false);
      setAllProdIcon(true);
    }
  }, [currentSection]);
  
  /** START CODE - AUTO LOGOUT AFTER 60 MIN */
  // const redirectTime = 1 * 60 * 60 * 1000 + 20 * 60 * 1000
  const redirectTime = 3 * 60 * 60 * 1000;
  useEffect(() => {
    const landingTime = localStorage.getItem("landingTime");
    localStorage.setItem("session", "1");
    if (!landingTime) {
      const currentTime = Date.now();
      localStorage.setItem("landingTime", currentTime.toString());
    }
    const checkRedirect = () => {
      const time = localStorage.getItem("landingTime");
      if (time) {
        const storedTime = localStorage.getItem("landingTime");
        const timeStored = parseInt(storedTime, 10);
        const currentTime = Date.now();
        const duration = currentTime - timeStored;

        if (duration >= redirectTime) {
          localStorage.removeItem("landingTime");
          localStorage.setItem("session", "0");
          props?.mfProps?.publish("core-header-ui-mf:signout");
        } else {
          const remainingTime = redirectTime - duration;
          setTimeout(checkRedirect, remainingTime);
        }
      }
    };
    checkRedirect();
  }, [navigate, redirectTime, props?.mfProps]);

  useEffect(() => {
    if (
      localStorage.getItem("session") &&
      localStorage.getItem("session") === "0"
    ) {
      localStorage.removeItem("landingTime");
    }
  }, []);

  /** END CODE - AUTO LOGOUT AFTER 60 MIN */

  const checkForTabSwitch = () => {
    if (changedFields.length > 0) {
      setTabSwitched(true);
    }
  };

  const homeIconClick = () => {
    navigate("/dashboard");
    setCurrentSection("home");
    setHomeIcon(false);
    setHomeIcon((prevState) => !prevState);
    setAllProdIcon(false);
    checkForTabSwitch();
  };

  const allProductClick = () => {
    navigate("/allproduct");
    setCurrentSection("allProducts");
    setAllProdIcon(false);
    setAllProdIcon((prevState) => !prevState);
    setHomeIcon(false);
    checkForTabSwitch();
  };

  const helpSupportClick = () => {
    const link = document.createElement('a');
    link.href =
      'https://kenvue.service-now.com/now/nav/ui/classic/params/target/incident.do%3Fsys_td%3D4fc368e647499a90786bc4af016d438e%26sysparm_stack%3D%26sysparm_view%3D';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  useEffect(() => {
    if (useLocations.pathname === "/allproducts" || 
      useLocations.pathname === "/allproduct") {
      setAllProdIcon(true);
      setHomeIcon(false);
      setCurrentSection("allProducts");
    } else if (useLocations.pathname === "/dashboard") {
      setHomeIcon(true);
      setAllProdIcon(false);
      setCurrentSection("home");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLocations?.pathname]);

  const getAllProductsImage = () => {
    if (open) {
      return <img src={allproducts} alt="" />;
    } else if (!allProdIcon) {
      return <img src={allproduct} alt="" />;
    } else {
      return <img src={allproducts} alt="" />;
    }
  };

  return window.location.pathname !== "/sip/login" ? (
    <Box className="sidebar-main">
      <CssBaseline />
      <Drawer className="sidebar-drawer" variant="permanent" open={open}>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 0 : "auto",
            justifyContent: "center",
          }}
        >
          {renderLogo(open)}
        </ListItemIcon>

        <List className="sidebar-list">
          <ListItemIcon
            onClick={homeIconClick}
            sx={{
              cursor: "pointer",
              float: "left",
              marginTop: "45px",
              minWidth: 0,
              mr: getWidth(open),
              justifyContent: "center",
            }}
          >
            <BootstrapTooltipSideBar
              className="BootstrapTooltipSidebar"
              title={<p className="BootstrapTooltipSidebar-p">My Product</p>}
            >
              {homeIcon || (homeIcon && open) ? (
                <img src={homegreen} alt="" />
              ) : (
                getHomeImage(open,homeIcon)
              )}
            </BootstrapTooltipSideBar>
          </ListItemIcon>

          <ListItemIcon
            onClick={allProductClick}
            sx={{
              cursor: "pointer",
              float: "left",
              marginTop: "35px",
              minWidth: 0,
              mr: getWidth(open),
              justifyContent: "center",
            }}
          >
            <BootstrapTooltipSideBar
              className="BootstrapTooltipSidebar"
              title={<p className="BootstrapTooltipSidebar-p">All Products</p>}
            >
              {allProdIcon || (allProdIcon && open) ? (
                <img src={allproductgreenopen} alt="" />
              ) : (
                getAllProductsImage()
              )}
            </BootstrapTooltipSideBar>
          </ListItemIcon>

          <ListItemIcon
            onClick={helpSupportClick}
            sx={{
              cursor: "pointer",
              float: "left",
              marginTop: "40px",
              marginBottom: "15px",
              minWidth: 0,
              mr: getWidth(open),
              justifyContent: "center",
            }}
          >
            <BootstrapTooltipSideBar
              className="BootstrapTooltipSidebar"
              title={
                <p className="BootstrapTooltipSidebar-p">Help & Support</p>
              }
            >
              {!open ? <img src={help} alt="" /> : <img src={helps} alt="" />}
            </BootstrapTooltipSideBar>
          </ListItemIcon>

          {!open ? (
            <Divider
              style={{
                borderColor: "white",
                width: "70%",
                marginTop: "35px",
                marginLeft: "10px",
              }}
            />
          ) : (
            <Divider
              style={{
                borderColor: "white",
                width: "85%",
                marginTop: "35px",
                marginLeft: "10px",
              }}
            />
          )}

          <ListItemIcon
            onClick={handleClickArrow}
            sx={{
              cursor: "pointer",
              minWidth: 0,
              marginTop: "25px",
            }}
          >
            <BootstrapTooltipSideBar
              className="BootstrapTooltipSidebar"
              title={
                <p className="BootstrapTooltipSidebar-p">Expand/Collapse</p>
              }
            >
              {!open ? (
                <img src={openBar} alt="" />
              ) : (
                <img src={close} alt="" />
              )}
            </BootstrapTooltipSideBar>
          </ListItemIcon>
          <ListItemText primary="Home" sx={{ opacity: getOpacity(open)}} />

          <ListItemIcon
            sx={{
              cursor: "pointer",
              marginTop: 18,
              minWidth: 0,
              float: "bottom",
              mr: getWidth(open),
              justifyContent: "center",
              position: "fixed",
              bottom: 0,
              marginBottom: "48px",
              marginLeft:"10px"
            }}
            onClick={(event) => {
              if (confirm("Are you sure to Sign out?")) {
                localStorage.removeItem("landingTime");
                localStorage.setItem("session", "0");
                props?.mfProps?.publish("core-header-ui-mf:signout");
                return true;
              } else {
                event.stopPropagation();
                event.preventDefault();
              }
            }}
          >
            <BootstrapTooltipSideBar
              className="BootstrapTooltipSidebar"
              title={
                  <p className="BootstrapTooltipSidebar-p">Sign Out</p>
              }
            >
              {getSignOutIcon(open)}
            </BootstrapTooltipSideBar>
          </ListItemIcon>
          <ListItemText primary="Home" sx={{ opacity: getOpacity(open) }} />
        </List>
      </Drawer>
    </Box>
  ) : (
    <></>
  );
}
