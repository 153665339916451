export const TOTAL_SCORE_DESCRIPTION1: string =
    "The Green Chemistry score promotes using ingredients with less potential to harm the environment or result in real or perceived health concerns."
export const TOTAL_SCORE_DESCRIPTION2: string =
    "It includes three pillars:"
export const TOTAL_GAIA_CONTENT: string =
    "A measure of the potential environmental impacts on ingredients at the end of a product's life."
export const WATCH_LIST_CONTENT: string =
    "Flags ingredients for which there are real or perceived human and / or environmental health concerns."
export const RENEWABLE_ORIGIN_BONOUS_CONTENT: string =
    "Encourages the choice of Raw Materials sourced from a renewable origin."
export const WATCH_LIST_SCORE_HEADING: string =
    "What are the Watch List scores of the raw materials in the formulation?​"
export const WATCH_LIST_SCORE_DIRECTION_CONTENT: string =
    " Kenvue's Watch List flags ingredients for which there are new, emerging scientific questions around human and/or environmental health. Inclusion on the list also reflects non-scientific issues, some of which can cascade from scientific debate, e.g., negative public perception, supply chain disruption. Constituent Watch List scores range from 3 to 5. The product Watch List score is a maximum of 15 and is subtracted from the Green Chemistry score as a penalty to account for the increased likelihood that reformulation will be required. It is calculated by summing the number of constituents in the formula that are on the Watch List, with weighting to account for the Watch List score of those constituents. A constituent with a score of 5 automatically disqualifies the product from being considered more sustainable. Constituents with a score of 4 are weighted more heavily than those with a score of 3."
export const WATCH_LIST_SCORE_GOOD_JOB_CONTENT: string =
    "There are no Watch List ingredients present in the formula."
export const WATCH_LIST_SCORE_WATCH_OUT_SCORE_5_CONTENT: string =
    "There are one or more ingredients with a Watch List score of 5 present in the formula. This formulation will automatically be given a Green Chemistry score of \"Very Poor\"."
export const WATCH_LIST_SCORE_WATCH_OUT_CONTENT: string =
    "There are one or more Watch List ingredients present in the formula."
export const RENEWABLE_ORIGIN_BONOUS_KENVUE_CONTENT: string =
    "Choosing raw materials with a renewable origin rather than a petrochemical origin reduces reliance on finite fossil resources. Through raw material governance processes, we can preferably use renewable raw materials generated using agricultural practices that preserve biodiversity."
export const RENEWABLE_ORIGIN_BONOUS_CONTENT_WATCHOUT: string =
    "This product has a regression in either the GAIA score or Watch List score and does not qualify for a Renewable Origin Bonus."