import React, { useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  IconButton,
  Box,
  Grid,
  InputAdornment,
  TableSortLabel,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  CircularProgress,
  Popper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  Delete as DeleteIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import InfoIcon from "@mui/icons-material/Info";

import "../../../assets/css/formulationtables.scss";
import {
  PRODUCT_ENVIRONMENTAL_FOOTPRINT_TOOLTIP,
  CARBON_FOOTPRINT_FOOTPRINT_TOOLTIP,
  GREEN_CHEMISTRY_FOOTPRINT_TOOLTIP,
} from "../../../constants/ExperimentalTooltip.constant";
import { BootstrapTooltip } from "../../../constants/Formula.constant";
import useFormulationTable from "./useFormulationTable";
import { RawMaterialsData } from "../../../structures/formulation";
import leafIcon from "../../../assets/images/leaf_icon_formulation.svg";
import warningIcon from "../../../assets/images/Warning_icon_formulation.svg";
import { getComparator, stableSort } from "../../../helper/PackagingFormuationFuncations";

const TwoLineTypography = styled(Typography)(() => ({
  width: "100%",
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
  wordWrap: "break-word",
  display: "inline-block",
  fontFamily: "kenvue-sans-regular",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  "&::after": {
    content: '""',
    whiteSpace: "pre",
  },
}));
const CustomTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& input": {
      textOverflow: "ellipsis", // Ensure ellipsis is shown for overflowing text
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "block",
    },
    "&:hover input": {
      overflow: "visible",
      whiteSpace: "normal",
    },
  },
  "& fieldset": {
    border: "none", // Remove border around the TextField
  },
  "& .MuiOutlinedInput-root:hover fieldset": {
    border: "none", // Ensure no border on hover
  },
  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
    border: "none",
  },
}));

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="right-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: "1px solid black", // Border color
    borderRadius: "10px 10px 10px 0px", // Border radius
    padding: "8px 12px", // Padding
    marginTop: "-15px",
    fontFamily: "kenvue-sans-regular",
    lineHeight: 1.5,
    marginLeft: "-5px !important",
    transformOrigin: "center bottom", // Tooltip opens above the element
  },
}));
interface FormulationAndCompositionTableProp {
  formulationRawMaterials: RawMaterialsData[];
  handelFormulationTableChanges: (
    formulationRawMaterials: RawMaterialsData[]
  ) => void;
  isClear: boolean;
  mode: string;
}

const FormulationAndCompositionTable: React.FC<
  FormulationAndCompositionTableProp
> = ({
  formulationRawMaterials,
  handelFormulationTableChanges,
  isClear,
  mode,
}) => {
  const {
    rows,
    searchValue,
    searchResults,
    order,
    orderBy,
    handleMassChange,
    handleSearchChange,
    handleDeleteRow,
    isFormulaCompositionEditable,
    handleMouseEnterWeight,
    handleMouseLeaveWeight,
    handleSearchSelect,
    errors,
    formattedTotalWeight,
    handleBlur,
    handleRequestSort,
    getColorByValue,
    isLoading,
    formattedTotalWeight_envFootprint,
    formattedTotalWeight_carbonFootprint,
    getTotalWeight,
    inFocusRows,
    setInFocusRows,
    anchorEl,
    isSearchResultsOpen,
  } = useFormulationTable({
    handelFormulationTableChanges,
    isClear,
    formulationRawMaterials,
  });
  const listRef = useRef<HTMLDivElement | null>(null);
  // function to validate user input
  const validateEnteredValue = (indexPosition: number, value: string) => {
    if (value.startsWith("0") && value !== "0" && !value.startsWith("0.")) {
      value = value.replace(/^0+/, "");
    }
    value = value.replace(/^[+-]/g, ""); // removing + and - signs if present

    // splitting the current value and checking there are only 6 decimal places
    const parts = value.split(".");
    if (parts.length > 1 && parts[1].length > 6) {
      parts[1] = parts[1].substring(0, 6);
      value = parts.join(".");
    }
    const zeroDotRegex = /^0\.0*$/;
    const zeroDecimalRegex = /^0\.\d$/;
    if (
      value === "0." ||
      zeroDotRegex.exec(value) ||
      zeroDecimalRegex.exec(value)
    ) {
      handleMassChange(indexPosition, value);
      return;
    }

    // Check for making sure the entered value is in 0-100 range
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      value = "0";
    } else if (numericValue > 100) {
      value = "100"; // number above 100 is reset to 100
    } else if (numericValue < 0) {
      value = "0"; // number below 0 is reset to 0
    }

    handleMassChange(indexPosition, value.toString()); // converting float value back again to string as function expects
  };

  const handleRowBlur = (rowId: string, value: string, index: number) => {
    handleBlur(rowId, value);
    setInFocusRows((prevRows) =>
      prevRows?.filter((rowPos) => rowPos !== index)
    );
  };

  const getBorderColor = (error: string | null | undefined) => {
    return error ? "2px solid red" : "2px solid #00B097";
  };

  const getCappedValue = (value: number) => {
    if (!value) return 0;
    if (value > 100) return 100;
    return value;
  };

  const getSearchResultHeight = () => {
    return searchResults.length > 0 ? "250px" : "0px";
  };
  const getSortDirection = (field) => (orderBy === field ? order : false);
  const getDirection = (field) => (orderBy === field ? order : "asc");

  return (
    <div className="table-wrapper1">
      <Grid container style={{ maxWidth: "1326.62px" }}>
        <Grid style={{ width: "100%" }}>
          <TableContainer component={Paper} className="table-containers">
            <Table stickyHeader>
              <TableHead className="table-head">
                <TableRow>
                  {/* <TableCell sx={{ width: '2%', paddingLeft: '1px' }}></TableCell>{} */}
                  <TableCell
                    className="table-cell0"
                    sx={{ width: "242px !important" }}
                    sortDirection={getSortDirection("tradeName")}
                    rowSpan={2}
                  >
                    <TableSortLabel
                      active={true}
                      direction={getDirection("tradeName")}
                      onClick={() => handleRequestSort("tradeName")}
                      hideSortIcon={false}
                      // style={{icon :orderBy === 'tradeName' ? {fontWeight:'bold'} : {opacity:0.5},}}
                    >
                      <TwoLineTypography variant="body1">Raw Material Trade Name</TwoLineTypography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className="table-cell1"
                    sx={{ width: "155px !important" }}
                    sortDirection={getSortDirection("rawMaterialId")}
                    rowSpan={2}
                  >
                    <TableSortLabel
                      active={true}
                      direction={getDirection("rawMaterialId")}
                      onClick={() => handleRequestSort("rawMaterialId")}
                      hideSortIcon={false}
                    >
                      <TwoLineTypography variant="body1">Raw Code</TwoLineTypography>
                    </TableSortLabel>
                  </TableCell>

                  <TableCell
                    className="table-cell2"
                    sx={{ width: "155px !important" }}
                    sortDirection={getSortDirection("percentage")}
                    rowSpan={2}
                  >
                    <TableSortLabel
                      active={true}
                      direction={getDirection("percentage")}
                      onClick={() => handleRequestSort("percentage")}
                      hideSortIcon={false}
                    >
                      <TwoLineTypography variant="body1">Mass % Composition</TwoLineTypography>
                    </TableSortLabel>
                  </TableCell>

                  <TableCell sx={{ width: "0.5%" }} className="divider" rowSpan={2}></TableCell>
                  <TableCell
                    className="table-cell3"
                    sortDirection={getSortDirection("envFootprint")}
                    rowSpan={2}
                  >
                    <TableSortLabel
                      active={true}
                      direction={getDirection("envFootprint")}
                      onClick={() => handleRequestSort("envFootprint")}
                      className="table-sort-label"
                    >
                      <div className="cell-content">
                        <div className="cell-text">
                          <TwoLineTypography variant="body1">
                            Product Environmental Footprint
                          </TwoLineTypography>
                          <Typography className="typography">
                            (Points per Functional unit)
                          </Typography>
                        </div>
                        <BootstrapTooltip
                          className="BootstrapTooltip"
                          title={
                            <p className="BootstrapTooltip-p">
                              {PRODUCT_ENVIRONMENTAL_FOOTPRINT_TOOLTIP}
                            </p>
                          }
                          placement="top"
                          arrow
                        >
                          <InfoIcon className="packaging-InfoIcon" />
                        </BootstrapTooltip>
                      </div>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className="table-cell4"
                    sx={{ width: "227px !important" }}
                    sortDirection={getSortDirection("carbonFootprint")}
                    rowSpan={2}
                  >
                    <TableSortLabel
                      active={true}
                      direction={getDirection("carbonFootprint")}
                      onClick={() => handleRequestSort("carbonFootprint")}
                      hideSortIcon={false}
                      className="table-sort-label"
                    >
                      <div className="cell-content">
                        <div className="cell-text">
                          <TwoLineTypography variant="body1">Carbon Footprint</TwoLineTypography>
                          <Typography className="typography">
                            {" "}
                            (g CO2 eq. per functional unit){" "}
                          </Typography>
                        </div>
                        <BootstrapTooltip
                          className="BootstrapTooltip"
                          title={
                            <p className="BootstrapTooltip-p">
                              {CARBON_FOOTPRINT_FOOTPRINT_TOOLTIP}
                            </p>
                          }
                        >
                          <InfoIcon className="packaging-InfoIcon" />
                        </BootstrapTooltip>
                      </div>
                    </TableSortLabel>
                  </TableCell>

                  <TableCell
                    className="green-chemistry"
                    sx={{
                      width:"226.62px",
                      height: "36px",
                      border: "1px solid #E4E7EC",
                      textAlign: "center",
                    }}
                    colSpan={2}
                  >
                    <div className="cell-content" style={{ paddingLeft: "10px" }}>
                      <TwoLineTypography
                        variant="body1"
                        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        Green Chemistry
                        <BootstrapTooltip
                          className="BootstrapTooltip"
                          title={
                            <p className="BootstrapTooltip-p">
                              {GREEN_CHEMISTRY_FOOTPRINT_TOOLTIP}
                            </p>
                          }
                        >
                          <InfoIcon className="packaging-InfoIcon" style={{ marginTop: "0px" }} />
                        </BootstrapTooltip>
                      </TwoLineTypography>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow
                  className="table-header-secondrow"
                  sx={{
                    position: "sticky",
                    top: "36px",
                    height: "42px",
                    background: "red",
                  }}
                >
                  <TableCell
                    className="css-iul604-MuiTableCell-root"
                    sx={{
                      borderTop: "1.5px solid #E4E7EC",
                      borderRight: "1.5px solid #E4E7EC",
                      borderLeft: "1px solid #E4E7EC",
                      width: "107.62px!important",
                      padding: "0px!important",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      margin="10px"
                      gap="2px"
                      justifyContent={"center"}
                    >
                      <TwoLineTypography variant="body1">GAIA Score</TwoLineTypography>
                      <TableSortLabel
                        active={true}
                        direction={getDirection("gaiaScore")}
                        onClick={() => handleRequestSort("gaiaScore")}
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    className="css-iul604-MuiTableCell-root"
                    sx={{
                      borderTop: "1.5px solid #E4E7EC",
                      borderRight: "1.5px solid #E4E7EC",
                      borderLeft: "1px solid #E4E7EC",
                      width: "119px!important",
                      padding: "0px!important",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      margin="10px"
                      gap="2px"
                      justifyContent={"center"}
                    >
                      <TwoLineTypography variant="body1">Indicators</TwoLineTypography>
                    
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body-formulation">
                {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                  const error = errors?.get(row.rawMaterialId);
                  const emptyOrUndefined = (value: string | undefined) => {
                    return value === "" || value === undefined;
                  };
                  const fullValue = emptyOrUndefined(row.percentage)
                    ? 0
                    : parseFloat(row?.percentage?.toString()).toFixed(6);
                  return (
                    <TableRow key={row.rawMaterialId+index} className="table-row">
                      <TableCell className="table-cell-row" sx={{ width: "242px !important" }}>
                        <Box className="table-box">
                          {isFormulaCompositionEditable && (
                            <IconButton
                              className={"icon-button"}
                              disabled={!isFormulaCompositionEditable}
                              onClick={() => handleDeleteRow(index)}
                            >
                              {mode !== "view" && <DeleteIcon />}
                            </IconButton>
                          )}
                          <Tooltip
                            title={row.tradeName}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -30], // Adjust the vertical offset here
                                  },
                                },
                              ],
                            }}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  color: "#111010",
                                  mb: "-29px",
                                  fontSize: "10px",
                                  fontWeight: "600",
                                },
                              },
                            }}
                            placement="top-start"
                          >
                            <CustomTextField
                              variant="outlined"
                              value={row.tradeName}
                              inputProps={{ readOnly: true }}
                              fullWidth
                              className="disabledfield"
                              sx={{
                                "& .MuiInputBase-input": {
                                  fontSize: "13.33px",
                                  height: "20px",
                                  padding: "0px",
                                },
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell
                        className="table-cell5"
                        sx={{
                          width: "155px !important",
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        <Box className="table-box1">
                          <TextField
                            variant="outlined"
                            value={row.rawMaterialId}
                            inputProps={{ readOnly: true }}
                            fullWidth
                            className="disabledfield"
                            InputProps={{
                              style: {
                                fontFamily: "kenvue-sans-regular",
                                fontSize: "13.33px",
                                fontWeight: 400,
                                lineHeight: "19.99px",
                                paddingLeft: "0px", // Adjust the right padding here
                                paddingRight: "0px",
                              },
                            }}
                            sx={{
                              "& fieldset": {
                                border: "none", // Remove the border around the TextField
                              },
                              "& .MuiOutlinedInput-root": {
                                padding: "0px",
                                "&:hover fieldset": {
                                  border: "none",
                                  paddingLeft: "0px", // Adjust the right padding here
                                  paddingRight: "0px",

                                  // Ensure no border on hover
                                },
                                "&.Mui-focused": {
                                  "& fieldset": {
                                    border: "none",
                                  },
                                },
                              },
                            }}
                          />
                        </Box>
                      </TableCell>

                      <TableCell
                        className="table-cell9"
                        sx={{
                          width: "155px !important",
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        <CustomTooltip title={error || fullValue}>
                          <TextField
                            value={
                              inFocusRows?.includes(index)
                                ? row.percentage
                                : parseFloat(row.percentage).toFixed(2)
                            }
                            onChange={(e) => {
                              validateEnteredValue(index, e.target.value);
                            }}
                            variant="outlined"
                            fullWidth
                            type="number"
                            disabled={!isFormulaCompositionEditable}
                            onBlur={(e) => {
                              handleRowBlur(row.rawMaterialId, e.target.value, index);
                            }}
                            onFocus={() => setInFocusRows((prevRows) => [...prevRows, index])}
                            InputProps={{
                              endAdornment: <InputAdornment position="start" sx={{fontSize:"13.33px"}}>%</InputAdornment>,
                              sx: {
                                
                                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                                  {
                                    "-webkit-appearance": "none",
                                  },
                              },
                            }}
                            inputProps={{
                              step: "0.000001",
                              min: 0,
                              max: 100,
                              readOnly: !isFormulaCompositionEditable,
                            }}
                            sx={{
                              "& input": {
                                marginLeft: "15px",
                                maxWidth: "50px",
                                paddingY: "10px",
                                textAlign: "right",
                                cursor: !isFormulaCompositionEditable ? "not-allowed" : "text",
                                fontFamily: "kenvue-sans-regular",
                                fontSize: "13.33px",
                                fontWeight: 400,
                                lineHeight: "19.99px",
                              },
                              "& fieldset": {
                                border: error ? "2px solid red" : "none", // Red border if error
                              },
                              "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                  border: error ? "2px solid red" : "none", // Red border on hover if error
                                },
                                "&.Mui-focused": {
                                  "& fieldset": {
                                    border: mode !== "view" ? getBorderColor(error) : "none",
                                  },
                                },
                              },
                            }}
                          />
                        </CustomTooltip>
                      </TableCell>

                      <TableCell sx={{ width: "0.5%" }} className="divider"></TableCell>

                      <TableCell
                        className="table-cell6"
                        sx={{ width: "269px !important", margin: "0px" }}
                      >
                        <Box display="flex" alignItems="center">
                          <LinearProgress
                            sx={{
                              "--LinearProgress-thickness": "19px",
                              width: "90%",
                              background: "#D2D1D2",
                              [`& .MuiLinearProgress-bar`]: {
                                backgroundColor: getColorByValue(
                                  row.envFootprint ? row.envFootprint : 0
                                ),
                              },
                            }}
                            variant="determinate"
                            value={getCappedValue(row.envFootprint)}
                            className="linear-progress-bar"
                          />
                          <Typography variant="body2" className="progress-value" style={{ fontFamily: "kenvue-sans-regular" }}>{`${
                            row.envFootprint ? row.envFootprint.toFixed(6) : ""
                          }`}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell className="table-cell7" sx={{ margin: "0px" }}>
                        <Box display="flex" alignItems="center">
                          <LinearProgress
                            sx={{
                              "--LinearProgress-thickness": "19px",
                              width: "90%",
                              background: "#D2D1D2",
                              [`& .MuiLinearProgress-bar`]: {
                                backgroundColor: getColorByValue(
                                  row.carbonFootprint ? row.carbonFootprint : 0
                                ),
                              },
                            }}
                            variant="determinate"
                            value={getCappedValue(row.carbonFootprint)}
                            className="linear-progress-bar"
                          />
                          <Typography variant="body2" className="progress-value" style={{ fontFamily: "kenvue-sans-regular" }}>{`${
                            row.carbonFootprint ? row.carbonFootprint.toFixed(6) : ""
                          }`}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        className="table-cell8"
                        sx={{
                          width: "107.62px !important",
                          margin: "0px",
                          textAlign: "center",
                          borderLeft: "1px solid #E4E7EC!important",
                          padding: "16px 0px 16px 0px"
                        }}
                      >
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <Typography variant="body2" className="progress-value" style={{ fontFamily: "kenvue-sans-regular" }}>
                            {`${row.gaiaScore ? Number(row.gaiaScore).toFixed(2) : "0.00"}`}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell

className="table-cell8"

sx={{width:"119px !important", margin: "0px", borderLeft: "1px solid #E4E7EC!important", padding:"16px 0px 16px 0px" }}

>

<Box display="flex" alignItems="center" justifyContent="space-evenly">

 {/* Leaf Icon or Placeholder */}

 {row?.leaf_icon_boolean === 'LEAF' ? (

  <img src={leafIcon} alt="LEAF" />

 ) : (

  <Box width="33px" height="24px" /> // Placeholder for Leaf Icon

 )}



 {/* Warning Icon or Placeholder */}

 {row?.watchlist_icon_boolean === '1' ? (

  <img src={warningIcon} alt="WARNING" />

 ) : (

  <Box width="33px" height="24px" /> // Placeholder for Warning Icon

 )}

</Box>

</TableCell>






                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <div className="sticky-footer1">
              <div
                className={`disabledfield-wrapper ${
                  !isFormulaCompositionEditable ? "setWidthforwrapper" : ""
                }`}
              >
                {isFormulaCompositionEditable && (
                  <>
                    <TextField
                      value={searchValue}
                      onChange={handleSearchChange}
                      className="disabledfield"
                      placeholder="Search Raw material or code here..."
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: isLoading ? (
                          <CircularProgress
                            color="inherit"
                            size={20}
                            sx={{ position: "absolute", right: "5px" }}
                          />
                        ) : (
                          <p style={{ position: "absolute", right: "5px", minWidth: "20px" }}></p>
                        ),
                      }}
                      sx={{
                        width: "100%",
                        maxWidth: "400px",
                        marginBottom: "10px",
                        marginTop: "5px",
                        "& input": {
                          padding: "8px 14px",
                        },
                        "& fieldset": {
                          border: "none", // Remove the border around the TextField
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            border: "none", // Ensure no border on hover
                          },
                          "&.Mui-focused": {
                            "& fieldset": {
                              border: "2px solid #00B097",
                            },
                          },
                        },
                      }}
                    />
                    <Popper
                      open={isSearchResultsOpen}
                      anchorEl={anchorEl}
                      placement="top-start"
                      className="formulation-table-searchresults"
                    >
                      <div
                        ref={listRef}
                        className="search-results"
                        style={{
                          fontFamily:"kenvue-sans-regular",
                          height: getSearchResultHeight(),
                        }}
                      >
                        <Table>
                          <TableBody>
                            {mode !== "view" &&
                              searchResults?.map((result, index) => (
                                <TableRow
                                  key={`${index}+${result.tradeName}`}
                                  hover
                                  onClick={() => handleSearchSelect(result)}
                                >
                                  <TableCell style={{ fontFamily: "kenvue-sans-regular"}}>{result.tradeName}</TableCell>
                                  <TableCell style={{ fontFamily: "kenvue-sans-regular" }}>{result.rawMaterialId}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </div>
                    </Popper>
                  </>
                )}
              </div>
              {isFormulaCompositionEditable && (
                <div style={{width: "100%", borderBottom:"1px solid #e0e0e0"}}>

                </div>
              )}
              <div
                style={{                 
                 
                  paddingTop: isFormulaCompositionEditable ? "10px" : "",
                }}
              >
                <div className="notify">
                  <span className="notifyFont">Missing Raw Material? <span className="notifyFontt">Email Us</span></span>
                    
                </div>
                <div className="total-mass">
                  <Typography variant="h6">Total:</Typography>
                  <Typography
                    className="score-title"
                    onMouseEnter={handleMouseEnterWeight}
                    onMouseLeave={handleMouseLeaveWeight}
                  >
                    {formattedTotalWeight}%
                  </Typography>
                  {parseFloat(getTotalWeight().toFixed(6)) === 100 ? (
                    <CheckCircleOutlineIcon className="success-icon" />
                  ) : (
                    <HighlightOffIcon className="error-icon" />
                  )}
                </div>
                <div className="env-weight">
                  <Typography className="score-title">
                    {formattedTotalWeight_envFootprint}
                  </Typography>
                </div>

                <div className="carbon-weight">
                  <Typography className="score-title">
                    {formattedTotalWeight_carbonFootprint}
                  </Typography>
                </div>

                <div className="greenChem-weight">
                </div>
              </div>
             
            </div>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default FormulationAndCompositionTable;