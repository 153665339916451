import React, { useState, useEffect, useContext, useCallback } from "react";
import PieChartDials from "../common/PieChartDials";
import lock_out_warning from "../../assets/images/lock-out-warning.svg";
import great_job from "../../assets/images/large_great_job.svg";
import dials_without_data from "../../assets/images/dials_without_data.svg";
import dataInCompleteIcon from "../../assets/images/dataInCompleteIcon.svg";
import ideaIcon from "../../assets/images/idea.svg";
import {
  ProductEnvironmentalFootprint_series0,
  CarbonFootprint_series0,
  GreenChemistry_series0,
  SustainablePackaging_series0,
  ProductEnvironmentalFootprintFlipcardDescription,
  SustainablePackagingFlipcardDescription,
  GreenChemistryFlipcardDescription,
  CarbonFootprintFlipcardDescription,
} from "../../constants/PieChartDials.constant";
import { useNavigate } from "react-router-dom";
import result_arrow_full_small_left from "../../assets/images/result_arrow_full_small_left.svg";
import { ResultDataContext } from "../../contexts/resultData/ResultDataContext";
import { ProductDataContext } from "../../contexts/productData/ProductDataContext";
import {
  DialsResultProductAssessmentProps,
  IPieChartJSONSeries1,
} from "../breadcrumb/types";
import "../../assets/css/ProductAssessment.scss";
import { TrackGoogleAnalyticsEvent } from "./TrackGoogleAnalyticsEvent";
import { useGlobaldata } from "../../contexts/masterData/DataContext";
import { useConsumerPackagingContext } from "../consumer-packaging-tab";

const DialsResultProductAssessment: React.FC<
  DialsResultProductAssessmentProps
> = (props) => {
  const navigate = useNavigate();
  const { assessmentsData } = useContext(ProductDataContext);
     const {
        primaryData,
        secondaryData, 
      } = useConsumerPackagingContext();
  const {
    productEnvironmentalFootprintData,
    carbonFootprintData,
    sustainablePackagingData,
    greenChemistryData,
  } = useContext(ResultDataContext);
  const [dialStatus, setDialStatus] = React.useState("look_out");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { globaldata } = useGlobaldata();
  const loginUserName = React.useMemo(() => {
    return (
      sessionStorage.getItem("givenName") ||
      globaldata?.[0]?.loggedInUserDetails?.givenName
    );
  }, [globaldata]);

  const allCalculated = primaryData.every((item)=> item.isCalculated) && secondaryData.every((item)=> item.isCalculated)
  const handleCardClick = async (assessmentsData) => {
    // Prevent navigation if there's a text selection
    if (window.getSelection().toString()) {
      return;
    }
  
    // Construct the navigation path
    const pagePath = `/view-all-results/${assessmentsData.assessmentId}`;
  
    // Track the analytics event
    await TrackGoogleAnalyticsEvent("PAGE_VIEW", "View All Results", {
      loginUserName,
      PAGE_VIEW: pagePath,
    });
  
    // Navigate to the page
    navigate(pagePath);
  };
  
  const [
    productEnvironmentalFootprintSeries1,
    setProductEnvironmentalFootprintSeries1,
  ] = useState<IPieChartJSONSeries1[]>(
    productEnvironmentalFootprintData?.dials?.PieChartJSONSeries1
  );

  const [carbonFootprintSeries1, setCarbonFootprintSeries1] = useState<
    IPieChartJSONSeries1[]
  >(carbonFootprintData?.dials?.PieChartJSONSeries1);

  const [sustainablePackagingSeries1, setSustainablePackagingSeries1] =
    useState<IPieChartJSONSeries1[]>(
      carbonFootprintData?.dials?.PieChartJSONSeries1
    );
    const [greenChemistrySeries1, setGreenChemistrySeries1] =
    useState<IPieChartJSONSeries1[]>(
      greenChemistryData?.dials?.PieChartJSONSeries1
    );

    useEffect(() => {

      setProductEnvironmentalFootprintSeries1(
     
       productEnvironmentalFootprintData?.dials?.PieChartJSONSeries1 || []
     
      );
     
      setCarbonFootprintSeries1(
     
       carbonFootprintData?.dials?.PieChartJSONSeries1 || []
     
      );
     
      setSustainablePackagingSeries1(
     
       sustainablePackagingData?.dials?.PieChartJSONSeries1 || []
     
      );
     
      setGreenChemistrySeries1(
     
       greenChemistryData?.dials?.PieChartJSONSeries1 || []
     
      );
     
     }, [
     
      productEnvironmentalFootprintData?.dials?.PieChartJSONSeries1,
     
      carbonFootprintData?.dials?.PieChartJSONSeries1,
     
      sustainablePackagingData?.dials?.PieChartJSONSeries1,
     
      greenChemistryData?.dials?.PieChartJSONSeries1,
     
     ]);
     
     
     const getDialStatus = useCallback(() => {

      const scores = [
     
       productEnvironmentalFootprintData?.dials?.pie_chart_sub_title,
     
       carbonFootprintData?.dials?.pie_chart_sub_title,
     
       greenChemistryData?.dials?.pie_chart_sub_title,
     
       sustainablePackagingData?.dials?.pie_chart_sub_title,
     
      ];
     
      if (
     
       scores.some((score) => score === "Poor" || score === "Very Poor") ||
     
       scores.every((score) => score === "No Improvement")
     
      ) {
     
       return "look_out";
     
      }
     
      if (
     
       scores.some((score) => score === "Good" || score === "Excellent") &&
     
       scores.every(
     
        (score) =>
     
         score === "No Improvement" || score === "Good" || score === "Excellent"
     
       )
     
      ) {
     
       return "great_job";
     
      }
     
      return dialStatus;
     
     }, [
     
      productEnvironmentalFootprintData,
     
      carbonFootprintData,
     
      greenChemistryData,
     
      sustainablePackagingData,
     
      dialStatus,
     
     ]);
     
     
     
     useEffect(() => {
     
      setDialStatus(getDialStatus());
     
     }, [getDialStatus]);
     
     

     const renderDialsWithoutData = () => (

      <div className="dials_without_data_div1">
     
       <div className="dials_without_data_div2">
     
        <div className="dials_without_data_div2_1">
     
         <img src={dials_without_data} alt="SIP" />
     
        </div>
     
       </div>
     
       <div className="dials_without_data_label1">Nothing here yet! </div>
     
       <div className="dials_without_data_label2">{props.dials_without_data_show_msg}</div>
     
      </div>
     
     );
     
     
     
     const renderViewResultButton = () => (
     
      <div className="pie_chart_main_div_view_result">
     
       <button
     
        style={{
     
         cursor: "pointer",
     
         border: "none",
     
         backgroundColor: "transparent",
     
         fontFamily: "kenvue-sans-regular",
     
         color: "#000",
     
        }}
     
        onClick={() => handleCardClick(assessmentsData)}
     
       >
     
        View All Results
     
       </button>
     
      </div>
     
     );
     
     
     
     const renderBackToAssessmentButton = () => (
     
      <div className="result_back_to_assessment">
     
       <div style={{ display: "flex", alignItems: "center" }}>
     
        <button
     
         style={{
     
          cursor: "pointer",
     
          display: "flex",
     
          alignItems: "center",
     
          background: "none",
     
          border: "none",
     
         }}
     
         onClick={() => navigate(`/product-assessment/${assessmentsData.assessmentId}`)}
     
        >
     
         <img
     
          src={result_arrow_full_small_left}
     
          alt="Back Arrow"
     
          style={{ marginRight: "8px", marginLeft: "-14px" }}
     
         />Back to Assessment
     
        </button>
     
       </div>
     
      </div>
     
     );
     
     
     
     const renderAssessmentLabel = () => {
     
      return dialStatus === "great_job" ? (
     
       <div className="pie_chart_great_jon_div">
     
        <div>
     
         <img src={great_job} alt="SIP" />
     
        </div>
     
        <div className="pie_chart_lock_out_label1">Great Job!</div>
     
        <div className="pie_chart_lock_out_label2">
     
         This product meets Kenvue's sustainable product innovation commitment.
     
        </div>
     
       </div>
     
      ) : (
     
       <div className="pie_chart_lock_out_div">
     
        <div>
     
         <img src={lock_out_warning} alt="SIP" />
     
        </div>
     
        <div className="pie_chart_lock_out_label1">Look out!</div>
     
        <div className="pie_chart_lock_out_label2">
     
         This product does not meet Kenvue's sustainable product innovation commitment. Can you identify the driver(s) and consider how we might improve the product's sustainability?
     
        </div>
     
       </div>
     
      );
     
     };
     
     
     
     const renderPieChartDials = () => {
     
      const pieCharts = [
     
       { data: productEnvironmentalFootprintSeries1, title: "Product Environmental Footprint", subTitle: productEnvironmentalFootprintData.dials.pie_chart_sub_title, percentage: productEnvironmentalFootprintData.dials.pie_chart_percentage, series0: ProductEnvironmentalFootprint_series0, series1: productEnvironmentalFootprintSeries1, description: ProductEnvironmentalFootprintFlipcardDescription, tabIndex: 1 },
     
       { data: carbonFootprintSeries1, title: "Carbon Footprint", subTitle: carbonFootprintData.dials.pie_chart_sub_title, percentage: carbonFootprintData.dials.pie_chart_percentage, series0: CarbonFootprint_series0, series1: carbonFootprintSeries1, description: CarbonFootprintFlipcardDescription, tabIndex: 2 },
     
       { data: greenChemistrySeries1, title: "Green Chemistry", subTitle: greenChemistryData.dials.pie_chart_sub_title, percentage: greenChemistryData.dials.pie_chart_percentage, series0: GreenChemistry_series0, series1: greenChemistrySeries1, description: GreenChemistryFlipcardDescription, tabIndex: 3 },
     
       { data: sustainablePackagingSeries1, title: "Sustainable Packaging", subTitle: sustainablePackagingData?.dials?.pie_chart_sub_title ?? "", percentage: sustainablePackagingData?.dials?.pie_chart_percentage ?? "", series0: SustainablePackaging_series0, series1: sustainablePackagingSeries1, description: SustainablePackagingFlipcardDescription, tabIndex: 4 }
     
      ];
     
     
     
      return pieCharts.map((chart, index) => (
     
       chart.data !== undefined && (
     
        <PieChartDials
     
         key={index+1}
     
         chartDivIndex={(index + 1).toString()}
     
         title={chart.title}
     
         sub_title={chart.subTitle}
     
         pie_chart_percentage={chart.percentage}
     
         data_series0={chart.series0}
     
         data_series1={chart.series1}
     
         flipcard_description={chart.description}
     
         selectedpiechart={props.selectedtab === chart.tabIndex ? "selected" : undefined}
     
        />
     
       )
     
      ));
     
     };
     
     
     
     const renderIncompleteDataMessage = () => (
     
      <div
     
       style={{   
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "12px",
      }}
     
      >
     
       <img src={dataInCompleteIcon} alt="Data incomplete" />
     
       <span style={{ fontFamily: "kenvue-sans-regular", fontSize: "16px" }}>
     
        There are one or more incomplete packaging components that are not included in the results.
     
       </span>
     
      </div>
     
     );
     
     
     
     const renderRecalculateMessage = () => (
     
      <div
     
       style={{
     
        display: "flex",
     
        justifyContent: "flex-end",
     
        alignItems: "center",
     
        gap: "12px",
     
       }}
     
      >
     
       <img src={ideaIcon} alt="Data incomplete" />
     
       <span style={{ fontFamily: "kenvue-sans-regular", fontSize: "16px" }}>
     
        New changes made. Please recalculate to see updated scores
     
       </span>
     
      </div>
     
     );
     
     
     
     return (
     
      <div className="pie_chart_main_div">
     
       <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
     
        {props.dials_without_data_show === "yes" ? renderDialsWithoutData() : (
     
         <>
     
          {props.page === "product-assessment" ? renderViewResultButton() : renderBackToAssessmentButton()}
     
          <div className="assessmentLabel">
     
           {renderAssessmentLabel()}
     
           {renderPieChartDials()}
     
          </div>
     
          {!assessmentsData.isPackagingDataCompleted ? renderIncompleteDataMessage() : (!allCalculated && renderRecalculateMessage())}
     
         </>
     
        )}
     
       </div>
     
      </div>
     
     );
     
     
};

export default DialsResultProductAssessment;
