import { Link, useNavigate } from "react-router-dom";
import { IBreadcrumbData } from "./types";
import React, { useContext } from "react";
import { SidebarContext } from "../../contexts/sidebarData/SidebarStateContext";
import { AutoSaveContext } from "../../contexts/autoSaveContext/AutoSaveContext";
import { truncate } from "../../helper/GenericFunctions";

interface IBreadcrumbProps extends IBreadcrumbData {
  path: string;
}

export const CommonBreadcrumb: React.FC<IBreadcrumbProps> = ({
  productID,
  productName,
  experimentalID,
  experimentalName,
  path,
}: IBreadcrumbProps) => {
  const navigate = useNavigate();
  const { currentSection } = useContext(SidebarContext);

  const { setTabSwitched, changedFields } = useContext(AutoSaveContext);

  const checkForTabSwitch = () => {
    if (changedFields.length > 0) {
      setTabSwitched(true);
    }
  };

  let currentLink = "";
  const crumbs: React.ReactElement[] = path
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink = `| ${crumb}`;
      return (
        <div key={crumb}>
          <Link style={{ color: "black" }} to={currentLink}>
            {crumb}
          </Link>
        </div>
      );
    });

  const renderBreadCrumbContent = () => {
    if (
      (crumbs[0].props.children.props.children === "product-assessment" ||
        crumbs[0].props.children.props.children === "view-all-results") &&
      experimentalID !== ""
    ) {
      return (
        <div style={{ display: "flex", width: "auto" }}>
          <div style={{ width: "flex" }}>
            <button
              className="breadcrumb1"
              onClick={() => {
                checkForTabSwitch();
                currentSection === "home"? navigate("/dashboard"): navigate("/allproduct");
              }}
              style={{
                marginTop: "1px",
                textDecoration: "none",
                cursor: "pointer",
                fontFamily: "kenvue-sans-regular",
                border: "none",
                backgroundColor: "transparent",
                padding: "0px",
                color:'#000',
                fontSize: "13.33px",
                fontWeight: 400,
                lineHeight: "19.99px",
              }}
            >
              {currentSection === "home" ? "Home" : "All Product"}{" "}
              <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>|</span>
            </button>
          </div>
          <div style={{ width: "flex", marginLeft: "5px" }}>
            <button
              onClick={() => {
                checkForTabSwitch();
                navigate(`/my-product-detail/${productID}`);
              }}
              style={{
                textDecoration: "none",
                cursor: "pointer",
                fontFamily: "kenvue-sans-regular",
                border: "none",
                backgroundColor: "transparent",
                padding: "0px",
                textAlign:"left",
              }}
            >
              {productName}
              <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>|</span>
            </button>
          </div>
          <div style={{ width: "flex", marginLeft: "5px" }}>
            <button
              className="findingDiv"
              style={{
                cursor: "pointer",
                textDecoration: "none",
                width: "flex",
                pointerEvents: "none",
                fontFamily: "kenvue-sans",
                fontWeight: "700",
                border: "none",
                backgroundColor: "transparent",
                padding: "0px",
              }}
            >
              {truncate(experimentalName, 50)}
            </button>
          </div>
        </div>
      );
    } else if (
      crumbs[0].props.children.props.children === "my-product-detail" &&
      experimentalID === ""
    ) {
      return (
        <div style={{ display: "flex", width: "auto" }}>
          <div style={{ width: "flex" }}>
            <button
              onClick={() => {
                checkForTabSwitch();
                currentSection === "home"? navigate("/dashboard"): navigate("/allproduct");
              }}
              style={{
                textDecoration: "none",
                cursor: "pointer",
                fontFamily: "kenvue-sans-regular",
                border: "none",
                backgroundColor: "transparent",
                padding: "0px",
                color:'#000'
              }}
            >
              {currentSection === "home" ? "Home" : "All Product"}
              <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>|</span>
            </button>
          </div>
          <button
            style={{
              width: "flex",
              pointerEvents: "none",
              fontFamily: "kenvue-sans",
              fontWeight: "700",
              border: "none",
              backgroundColor: "transparent",
              padding: "0px",
            }}
          >
            &nbsp;{productName}
          </button>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", width: "auto", alignItems: "baseline" }}>
          <div style={{ width: "flex" }}>
            <Link
              style={{
                textDecoration: "none",
                fontFamily: "kenvue-sans-regular",
                fontSize: "13.33px",
                fontWeight: 400,
                lineHeight: "19.99px",
                textAlign: "left",
                color:'#000'
              }}
              to={currentSection === "home" ? '/dashboard' :'/allproduct'}
            >
              {currentSection === "home" ? "Home" : "All Product"}
              <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>|</span>
            </Link>
          </div>
          <div>
            <button
              style={{
                width: "flex",
                pointerEvents: "none",
                fontFamily: "kenvue-sans",
                fontWeight: "700",
                fontSize: "13.33px",
                border: "none",
                backgroundColor: "transparent",
                lineHeight: "19.99px",
                padding: "0px",
              }}
            >
              &nbsp;{productName}
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        marginTop: "5px",
        padding: "16px",
      }}
    >
      {renderBreadCrumbContent()}
    </div>
  );
};
