/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ChangeEvent,
  useContext,
} from "react";
import {
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TextField,
  Typography,
  IconButton,
  Box,
  Grid,
  InputAdornment,
  TableRow,
  Popper
} from "@mui/material";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from "@mui/icons-material";
import { BootstrapTooltip } from "../../constants/Formula.constant";
import InfoIcon from "@mui/icons-material/Info";
import "../../assets/css/packagingtable.css";
import "../../assets/css/SIP.css";
import SortableTableHeader from "./SortableTableHeader";
import { useGlobaldata } from "../../contexts/masterData/DataContext";
import { MaterialEntity, MaterialFieldsExistType, PackagingTableProps } from "../../structures/packaging";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import SelectField from "../formulaAndConsumer/SelectField";
import ProgressBarWithLabel from "../formulaAndConsumer/ProgressBarWithLabel";
import { useConsumerPackagingContext } from "./ConsumerPackagingContext";
import { ProductDataContext } from "../../contexts/productData/ProductDataContext";
import { getComparator, stableSort } from "../../helper/PackagingFormuationFuncations";
import deleteIcon from "../../assets/images/delete-pacaking.svg";
import { CheckCRUDAccess } from "../../helper/GenericFunctions";

const StyledIconButton = styled(IconButton)({
  "& img": {
    width: "20px",
    height: "20px",
    marginRight: "4px",
    filter: "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg)", // Black color
    transition: "filter 0.3s ease", // Smooth transition effect
  },
  "&:hover img": {
    filter: "invert(19%) sepia(91%) saturate(7490%) hue-rotate(357deg) brightness(93%) contrast(101%)", // Red color
  },
});
export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="right-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: "1px solid black", // Border color
    borderRadius: "10px 10px 10px 0px", // Border radius
    padding: "8px 12px", // Padding
    marginTop: "-21px",
    fontFamily: "kenvue-sans-regular",
    lineHeight: 1.5,
    transformOrigin: "center bottom", // Tooltip opens above the element
  },
}));

// PackagingTable component
const PackagingTable: React.FC<PackagingTableProps> = ({
  updateSaveButtonState,
  material,
  errors,
  setErrors,
  componentId,
  packagingtype,
  isAdd,
  isImportData,
  fieldsExistData
}) => {
  const { packagingData } = useGlobaldata(); // Ensure this hook is correctly imported
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchResults, setSearchResults] = useState<MaterialEntity[]>([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof MaterialEntity>("_id");
  const [isHoveredtotal, setIsHoveredtotal] = useState(false);
  const [isFocused, setIsFocused] = useState<number | null>(null);
  const [isFocusedSerch, setIsFocusedSerch] = useState<boolean>(false);
  const [rows, setRows] = useState<MaterialEntity[]>(material);
  const [isRowChange, setIsRowChange] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLInputElement>(null);
  const [isSearchResultsOpen, setIsSearchResultsOpen] = useState<boolean>(false);
  // Use useCallback to memoize the functions
  const handleMouseEnterWeight = useCallback(() => setIsHoveredtotal(true), []);
  const handleMouseLeaveWeight = useCallback(
    () => setIsHoveredtotal(false),
    []
  );
  const { assessmentsType,usersData } = useContext(ProductDataContext);
  const { primaryData, secondaryData, handelChangeTableData } = useConsumerPackagingContext();

  const dataSet: MaterialEntity[] = packagingData.packagingMaterial
    ? packagingData.materials.map((material, index) => {
      return {
        _id: index + 1, // Setting _id as index + 1, you can adjust the offset if needed
        material_name: material.name,
        material_type: "",
        converting_process: "",
        material_pct: "",
        productEnvironmentalFootPrint: "",
        carbonFootPrint: "",
        virginPlasticValue: "",
      };
    })
    : [];

  // Disable the field if assessmentsType is 'baseline' or 'final' and isEdited is true
  const isFieldDisabled = useMemo(() => {
    return assessmentsType === "baseline" || assessmentsType === "final";
  }, [assessmentsType]);

  const handleMassChange = useCallback((id: number, newMass: string) => {
    setIsRowChange(true);
    const updatedMass =
      newMass.trim() === "" ? "0" : newMass.replace(/^0+/, "");
    setRows((prevRows) =>
      prevRows.map((row) =>
        row._id === id ? { ...row, material_pct: updatedMass } : row
      )
    );

  }, []);

  const handleBlur = (id: number, value: string) => {
    setIsRowChange(true);
    const numValue = parseFloat(value);
    let errorMessage: string | null = null;

    if (numValue === 0) {
      errorMessage = "Weight cannot be 0, Please enter the valid weight";
    } else if (numValue < 0 || numValue > 100) {
      errorMessage =
        "Weight% should be in range of 0-100% only, Please enter valid range";
    }

    setErrors((prevErrors) => new Map(prevErrors).set(id, errorMessage));
    setIsFocused(null);
  };

  // Handle name change
  const handleNameChange = useCallback((id: number, newName: string) => {
    setIsRowChange(true);
    setRows((prevRows) =>
      prevRows.map((row) =>
        row._id === id ? { ...row, material_name: newName } : row
      )
    );

  }, []);

  const handleDeleteRow = useCallback(
    (id: number) => {
      setIsRowChange(true);
      setRows((prevRows) => {
        const updatedRows = prevRows.filter((row) => row._id !== id);

        // Remove the error associated with the deleted row
        setErrors((prevErrors) => {
          const updatedErrors = new Map(prevErrors);
          updatedErrors.delete(id); // Remove the error for the deleted row
          return updatedErrors;
        });

        return updatedRows;
      });

    },
    []
  );

  const handleDropdownChange = useCallback(
    (id: number, field: keyof MaterialEntity, value: string) => {
      setIsRowChange(true);
      setRows((prevRows) =>
        prevRows.map((row) =>
          row._id === id ? { ...row, [field]: value } : row
        )
      );
    },
    []
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim();
      setSearchValue(value);
      setIsSearchResultsOpen(true)
      setAnchorEl(e.currentTarget)

      if (value === "") {
        setSearchResults([]);
        return;
      }

      const results = dataSet.filter((item) =>
        item.material_name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(results);
    },
    [dataSet]
  );

  const handleSearchSelect = useCallback((result: MaterialEntity) => {
    if (rows.some((row) => row._id === result._id)) return;
    setRows((prevRows) => {
      const newRel = {
        ...result,
        _id: prevRows.length + 1,
      };
      return [...prevRows, newRel];
    });
    setIsRowChange(true);
    setSearchValue("");
    setSearchResults([]);
  }, []);

  const handleRequestSort = useCallback(
    (property: keyof MaterialEntity) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const getTotalWeight = useCallback(() => {
    return rows
      .reduce((total, row) => total + parseFloat(row.material_pct ?? "0"), 0)
      .toFixed(6);
  }, [rows]);

  // Function to format numbers with full precision
  const formatNumber = (num: string | number): string => num.toString();

  // Function to format number to two decimal places without rounding
  const formatToTwoDecimalsWithoutRounding = (num: string | number): string => {
    const parsedNum = parseFloat(num.toString());
    if (isNaN(parsedNum)) return "0.00";
    return parsedNum.toFixed(2);
  };
  const getFormattedWeight = (): string => {
    const totalWeight = parseFloat(getTotalWeight());

    if (isHoveredtotal) {
      if (totalWeight === 100.0) {
        return totalWeight.toFixed(2);
      } else {
        return totalWeight.toFixed(6);
      }
    } else {
      return formatToTwoDecimalsWithoutRounding(getTotalWeight());
    }
  };

  const formattedTotalWeight = useMemo(() => getFormattedWeight(), [getTotalWeight, isHoveredtotal]);

  // Memoize callback to avoid unnecessary updates
  const handleRowsChange = useCallback(() => {
    if (updateSaveButtonState) {
      const totalWeight = parseFloat(getTotalWeight());
      const hasErrors = Array.from(errors.values()).some(
        (error) => error !== null
      );
      updateSaveButtonState(totalWeight === 100 && !hasErrors);
    }
  }, [rows, errors, updateSaveButtonState, getTotalWeight]);

  useEffect(() => {
    handleRowsChange();
  }, [rows, errors, handleRowsChange]);

  useEffect(() => {
    if (packagingtype === "Primary" && primaryData) {
      if (
        primaryData[componentId]?.pc_nm !== "" && (JSON.stringify(rows) !==
          JSON.stringify(primaryData[componentId]?.material))
      ) {
        setRows(primaryData[componentId]?.material || []);
      }
    }
    if (packagingtype === "Secondary" && secondaryData) {
      if (
        secondaryData[componentId]?.pc_nm !== "" && (JSON.stringify(rows) !== JSON.stringify(secondaryData[componentId]?.material))
      ) {
        setRows(secondaryData[componentId]?.material || []);
      }
    }
  }, [primaryData,secondaryData]);

  useEffect(() => {
    handelChangeTableData(
      rows,
      componentId || 0,
      packagingtype,
      isAdd,
      isImportData,
      isRowChange
    );
  }, [rows]);
  const getError = (errors, id) => errors.get(id || 0);
  const isFieldDisabledFn = (fieldsExistData, index, fieldName) => {
    const Fn = fieldsExistData?.length > 0 && !!fieldsExistData[index]?.[fieldName];
   
    return  (isFieldDisabled && Fn) || CheckCRUDAccess(usersData, "consumer_packaging") === 0

  }

  
  // Helper function to get type options
  const getTypeOptions = (materials, materialName) => {
    const material = materials?.find((item) => item.name === materialName);
    return material?.type || [];
  };


const hasValidFields = (fieldData: MaterialFieldsExistType): boolean => {
 return Boolean(
   fieldData?.material_name ||
   fieldData?.converting_process ||
   fieldData?.material_type ||
   fieldData?.material_pct
 );
};

  // Helper function to format full precision weight
  const getFullPrecisionWeight = (materialPct) => formatNumber(materialPct ?? "0");

  return (
    <div className="table-wrapper-packing">
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <TableContainer component={Paper} className="table-container">
              <Table stickyHeader>
                <TableHead
                  sx={{
                    "& th": {
                      backgroundColor: "#E4E7EC",
                      whiteSpace: "normal", // Allow normal white space
                      wordWrap: "break-word", // Allow words to break
                      height: "80px !important",
                      padding: "12px !important",
                    },
                  }}
                >
                  <TableRow>
                    <SortableTableHeader
                      className="table-cell0"
                      id="material_name"
                      label="Material Name"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={handleRequestSort}
                    />
                    <SortableTableHeader
                      className="table-cell1"
                      id="material_type"
                      label="PCR/PIR/ Virgin"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={handleRequestSort}
                    />
                    <SortableTableHeader
                      className="table-cell2"
                      id="converting_process"
                      label="Converting Process"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={handleRequestSort}
                    />
                    <SortableTableHeader
                      className="table-cell3"
                      id="material_pct"
                      label="Weight (in %)"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={handleRequestSort}
                      width="114px"
                    />
                    <TableCell className="divider"></TableCell>
                    <SortableTableHeader
                      className="table-cell4"
                      id="productEnvironmentalFootPrint"
                      label="Product Environmental Footprint"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={handleRequestSort}
                      infoIcon={
                        <BootstrapTooltip
                          className="BootstrapTooltip"
                          title={
                            <p className="BootstrapTooltip-p">
                              Leverages Life Cycle Assessment (LCA) methodology
                              to measure the total environmental impact of a
                              product.{" "}
                            </p>
                          }
                        >
                          <InfoIcon className="packaging-InfoIcon" />
                        </BootstrapTooltip>
                      }
                      subLabel="(Points per Functional unit)"
                    />
                    <SortableTableHeader
                      className="table-cell5"
                      id="carbonFootPrint"
                      label="Carbon Footprint"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={handleRequestSort}
                      infoIcon={
                        <BootstrapTooltip
                          className="BootstrapTooltip"
                          title={
                            <p className="BootstrapTooltip-p">
                              The carbon footprint of a raw material is the
                              total greenhouse gas emissions, particularly
                              carbon dioxide (CO2), generated during its
                              extraction, production, and transportation. It
                              quantifies the material's contribution to climate
                              change.
                            </p>
                          }
                        >
                          <InfoIcon className="packaging-InfoIcon" />
                        </BootstrapTooltip>
                      }
                      subLabel="(g CO2 eq. per functional unit)"
                    />
                    <SortableTableHeader
                      className="table-cell6"
                      id="virginPlasticValue"
                      label="g of Virgin Plastic"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={handleRequestSort}
                    />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                    const error = getError(errors, row._id);
                    const isDeleteFieldDisble = (fieldsExistData?.length > 0 && fieldsExistData[index])
                          ? !hasValidFields(fieldsExistData[index])
                      : false;
                    const isDeleteDisble = isFieldDisabled && isDeleteFieldDisble;
                    const borderColour = error ? "2px solid red" : "none";
                    const typeOption = getTypeOptions(packagingData.materials, row.material_name);
                    // Extract full precision weight value
                    const fullPrecisionWeight = getFullPrecisionWeight(row.material_pct);
                    return (
                      <TableRow
                        key={row._id}
                        sx={{
                          "& td": {
                            fontFamily: "kenvue-sans-regular",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "18px",
                            textAlign: "left",
                            padding: "1px 8px !important",
                          },
                        }}
                      >
                        <TableCell
                          className="table-cell0"
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            textAlign: "left",
                          }}
                        >
                          <CustomTooltip title={row.material_name}>
                          <Box sx={{ display: "flex" }}>
                           
                              <Tooltip title={"Delete"} placement="bottom">
                                <StyledIconButton
                                  className="disabledfield"
                                  onClick={() =>
                                    handleDeleteRow(row._id ? row._id : 0)
                                  }
                                  sx={{
                                    marginLeft: "-9px",
                                    padding: "1.5px !important",
                                    backgroundColor: "transparent !important",
                                    "&:disabled": {
                                      cursor: "not-allowed",
                                    },
                                  }}
                                  disabled={isDeleteDisble ||  CheckCRUDAccess(usersData, "consumer_packaging") === 0}
                                >
                                  <img src={deleteIcon} alt="Delete Material" />

                                </StyledIconButton>
                              </Tooltip>
                            
                            
                              <SelectField
                                value={row.material_name}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  handleNameChange(
                                    row._id ? row._id : 0,
                                    newValue
                                  );

                                }}
                                options={packagingData?.packagingMaterial || []}
                                disabled={
                                  isFieldDisabledFn(fieldsExistData, index, "material_name")
                                } />
                              {/* </Tooltip> */}
                            
                          </Box>
                          </CustomTooltip>
                        </TableCell>
                        <TableCell
                          className="table-cell1"
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            textAlign: "left",
                          }}
                        >
                          <SelectField
                            value={row.material_type}
                            onChange={(e) =>
                              handleDropdownChange(
                                row._id ? row._id : 0,
                                "material_type",
                                e.target.value
                              )
                            }
                            options={packagingData["pcr/pir/virgin"] || []}
                            disabled={
                              isFieldDisabledFn(fieldsExistData, index, "material_type")
                            } />
                        </TableCell>
                        <TableCell
                          className="table-cell2"
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            wordBreak: "break-word",
                            textAlign: "left",
                          }}
                        >
                          <CustomTooltip title={row.converting_process || ""}>
                            <span>
                              <SelectField
                                value={row.converting_process || ""}
                                onChange={(e) =>
                                  handleDropdownChange(
                                    row._id ? row._id : 0,
                                    "converting_process",
                                    e.target.value
                                  )
                                }
                                options={typeOption}
                                disabled={
                                  isFieldDisabledFn(fieldsExistData, index, "converting_process")
                                }
                              />
                            </span>
                          </CustomTooltip>
                        </TableCell>


                        <TableCell
                          className="table-cell3"
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            textAlign: "left",
                          }}
                        >
                          <CustomTooltip
                            title={
                              error ??
                              parseFloat(row.material_pct ?? "0").toFixed(6)
                            }
                          >
                            <TextField
                              className="disabledfield"
                              value={
                                isFocused === row._id
                                  ? fullPrecisionWeight // Show full precision value when focused
                                  : formatToTwoDecimalsWithoutRounding(
                                    row.material_pct ?? "0"
                                  ) // Show two decimal places without rounding by default
                              }
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleMassChange(
                                  row._id ? row._id : 0,
                                  e.target.value
                                );
                              }}
                              onFocus={() => setIsFocused(row._id ? row._id : 0)} // Set focus state for specific row
                              onBlur={(e) =>
                                handleBlur(row._id ? row._id : 0, e.target.value)
                              } // Validate and reset focus
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" sx={{ marginLeft: "0px" }}>
                                    %
                                  </InputAdornment>
                                ),
                                style: {
                                  backgroundColor: "rgb(249, 250, 251)",
                                  paddingRight: "5px", // Adjust the right padding here
                                  margin: "3px",
                                  width: "100px"
                                },
                                inputProps: {
                                  min: 0,
                                  max: 100,
                                },
                              }}
                              disabled={
                                isFieldDisabledFn(fieldsExistData, index, "material_pct")
                              }
                              type="number"
                              sx={{
                                "& fieldset": {
                                  border: borderColour, // Red border if error
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&:hover fieldset": {
                                    border: borderColour, // Red border on hover if error
                                  },
                                  "& .MuiInputBase-input": {
                                    padding: "16.5px 5px",
                                  },
                                  "&.Mui-focused": {
                                    "& fieldset": {
                                      border: error
                                        ? "2px solid red"
                                        : "2px solid #00B097",
                                    },
                                  },
                                  "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                  },
                                  "& input[type=number]::-webkit-outer-spin-button":
                                  {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                  },
                                  "& input[type=number]::-webkit-inner-spin-button":
                                  {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                  },
                                },
                              }}
                            />
                          </CustomTooltip>
                        </TableCell>
                        <TableCell className="divider" ></TableCell>
                        <TableCell
                          className="table-cell4"
                          style={{ fontFamily: "kenvue-sans-regular" }}
                          sx={{
                           
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >
                          <ProgressBarWithLabel
                            value={Number(row.productEnvironmentalFootPrint)}
                            color="#3F7AB4"
                            label={`${Number(
                              row.productEnvironmentalFootPrint
                            )}`}
                            
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >
                          <ProgressBarWithLabel
                            value={Number(row.carbonFootPrint)}
                            color="#3F7AB4"
                            label={`${Number(row.carbonFootPrint)}`}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="body2"
                              className="progress-value"
                            >
                              {`${Number(row.virginPlasticValue || 0)}`}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: isFocusedSerch ? "2px solid transparent !important" : "2px solid lightgray !important", // Default border 
                        transition: "border-color 0.5s ease,border-width 0.5s ease !important", // Smooth transition 
                        "&:hover": {
                          borderBottom: isFocusedSerch ? "2px solid transparent !important" : "2px solid #716c6c !important", // Darker border on hover 
                        },
                      }}

                      colSpan={4} className="footer-search">
                        <TextField
                          className="disabledfield"
                          value={searchValue}
                          onChange={handleSearchChange}
                          placeholder="Search by Material Name"
                          disabled={CheckCRUDAccess(usersData, "consumer_packaging") === 0}
                          onFocus={() => setIsFocusedSerch(true)}
                          onBlur={() => setIsFocusedSerch(false)}
                          sx={{
                            width: "100%",
                            marginBottom: "10px",
                            marginTop: "5px",
                            "& fieldset": {
                              border: "none", // Remove the border around the TextField
                            },
                            "& .MuiOutlinedInput-root": {
                              "&:hover fieldset": {
                                border: "none", // Ensure no border on hover
                              },
                              "&.Mui-focused": {
                                "& fieldset": {
                                  border: "2px solid #00B097",
                                },
                              },
                            },
                          }}
                        />

                      <Popper
                        open={isSearchResultsOpen}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                        className="packing-table-searchresults"
                      >
                        <TableContainer>
                          <Table>
                            <TableBody>
                              {searchResults.map((result) => (
                                <TableRow
                                  key={result._id}
                                  hover
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleSearchSelect(result)}
                                >
                                  <TableCell className="material-search-result" sx={{ height: "0px!important" }}>{result.material_name}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Popper>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell colSpan={3}>
                      <Box
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: "0px",
                          border: "none",
                        }}
                      >
                        <div
                          className="tableCell-Total"
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              marginRight: "5px",
                              fontFamily: "kenvue-sans-regular",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "21px",
                              textAlign: "left",
                            }}
                          >
                            Total:
                          </Typography>
                          <Typography
                            onMouseEnter={handleMouseEnterWeight}
                            onMouseLeave={handleMouseLeaveWeight}
                            sx={{
                              fontFamily: "kenvue-sans-regular",
                              fontSize: "14px",
                              fontWeight: "700",
                              lineHeight: "21px",
                              textAlign: "left",
                              border: "none",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {formattedTotalWeight}%
                          </Typography>

                          {parseFloat(getTotalWeight()) == 100 ? (
                            <CheckCircleOutlineIcon
                              sx={{
                                color: "green",
                                fontSize: 20,
                                marginLeft: "10px",
                              }}
                            />
                          ) : (
                            <HighlightOffIcon
                              sx={{
                                color: "red",
                                fontSize: 20,
                                marginLeft: "10px",
                              }}
                            />
                          )}
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PackagingTable;