import {
  Box,
  Select,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  FormControl,
} from "@mui/material";
import React, { useContext } from "react";
import InfoIcon from "@mui/icons-material/Info";
import MenuItem from "@mui/material/MenuItem";
import "../../../assets/css/tooltip.scss";
import "../../../assets/css/formulation-tab.scss";
import Tooltipcommon from "../../../controls/Tooltip";
import "../../../assets/css/SIP.css";
import DialogBox from "../../../controls/DialogBox";
import {
  FML_NUMBER_TOOLTIP_CONTENT,
  NET_CONTENT_TOOLTIP_CONTENT,
  PRODUCT_SEGMENT_TOOLTIP_CONTENT,
  PRODUCT_SUB_SEGMENT_TOOLTIP_CONTENT,
  PRODUCT_ZONE_TOOLTIP_CONTENT,
  SALES_ZONE_TOOLTIP_CONTENT,
  USE_DOSE_TOOLTIP_CONTENT,
  USE_SCENARIO_TOLTIP_CONTENT,
  WARNING_MSG_CANCEL,
} from "../../../constants/ExperimentalTooltip.constant";
import { useGlobaldata } from "../../../contexts/masterData/DataContext";
import FormulationAndCompositionTable from ".././formulationComposition/FormulationAndCompositionTable";
import PopupImportFormula from "../../breadcrumb/PopupComponentImportFormula";
import useFormulaAndConsumer from "./useFormulaAndConsumer";
import FormField, {
  ConsumablesUsedField,
  SelectFieldLable,
} from ".././FormField";
import { styled } from "@mui/material/styles";
import { ProductDataContext } from "../../../contexts/productData/ProductDataContext";
import lockImage from "../../../assets/images/lockicon.svg";
import { PartialDataWarning } from "../../common/PartialDataWarningFormulation";
import {
  CheckCRUDAccess,
  GetToastContainer,
} from "../../../helper/GenericFunctions";
import { AutoSaveContext } from "../../../contexts/autoSaveContext/AutoSaveContext";
import calculatingicon from "../../../assets/images/calculate.svg";
import FormulationLegends from "./FormulationLegends";

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="right-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: "1px solid black", // Border color
    borderRadius: "10px 10px 10px 0px", // Border radius
    padding: "8px 12px", // Padding
    marginTop: "-21px",
    fontFamily: "kenvue-sans-regular",
    lineHeight: 1.5,
    transformOrigin: "center bottom", // Tooltip opens above the element
  },
}));

const FormulaAndConsumer: React.FC = () => {
  const { formulationData: formulationMasterData } = useGlobaldata();
  const {
    usersData,
    formulation: assessmentFormulation,
    assessmentsData,
  } = useContext(ProductDataContext);
  const {
    handleChange,
    handelBlurUnit,
    errors,
    handleClickSaveButton,
    subSegments,
    dialogKey,
    importFormulaDialogOpen,
    mode,
    showWariningMsg,
    isClear,
    handleContinueDialogButton,
    handleCloseDialog,
    handleOpenImportFormulaPopup,
    handleClick1,
    handleClick2,
    handleCloseImportFormulaDialog,
    callChildData,
    handelFormulationTableChanges,
    formulationData,
    isWarningEditDialog,
    handleCloseEditWarningDialog,
    showImportFormula,
    handleContinueEditWarningDialogButton,
    isSaveEnable,
    isImportFormula,
    disabled,
    useDose,
    isExperimental,
    toggleSaveButton,
  } = useFormulaAndConsumer();
  const { taboutAutoSaveInProgress } = useContext(AutoSaveContext);
  const isOwner = CheckCRUDAccess(usersData, "formulation") === 1;

  const error = "TextField this filed";

  const getColor = (validator: boolean): string => {
    const color = validator ? "#444444BF" : "black";
    return color;
  };

  const getSaveButtonClassName = () => {
    if (isSaveEnable || taboutAutoSaveInProgress) {
      if (toggleSaveButton || taboutAutoSaveInProgress) {
        return "calculatingButton";
      } else {
        return "calculatingButtonNonToggle";
      }
    } else {
      return "whiteButtonFomulaCalculating";
    }
  };

  const getButtonText = () => {
    if (isSaveEnable || taboutAutoSaveInProgress) {
      return toggleSaveButton || taboutAutoSaveInProgress
        ? "Calculating"
        : "Calculate";
    }
    return "Calculate";
  };

  const showEdited = () => {
    return assessmentFormulation?.isEdited && !isExperimental;
  };

  const getCursorType = (value: boolean) => {
    if (value) {
      return "not-allowed";
    }
    return "pointer";
  };

  return (
    <>
      <Box className="formula-main-div">
        {!assessmentsData?.isFormulationDataCompleted && <PartialDataWarning />}
        <Box
          className="formula-1div"
          sx={{
            display: "grid",
            position: "relative",
            height: {
              xs: "100%", // For smaller screens (optional)
              sm: "160px", // Matches max-width: 880px
              md: "160px",
              xl: "160px",
            },
          }}
        >
          {/* FML Number Section */}
          <Box className="fml-number">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                fontWeight="700"
                fontSize={"13.33px"}
                sx={{ fontFamily: "kenvue-sans-regular", fontWeight: "700" }}
                variant="subtitle1"
              >
                {`FML Number ${showEdited() ? "(Edited)" : ""}`}
              </Typography>

              <Tooltipcommon
                content={FML_NUMBER_TOOLTIP_CONTENT}
                direction="fml-top"
                disable={disabled?.netContent || !isOwner}
              >
                <InfoIcon />
              </Tooltipcommon>
            </div>
            <TextField
              className="disabledfield"
              variant="standard"
              sx={{
                width: "100%",
              }}
              value={formulationData.fmlCode}
              InputProps={{
                readOnly: true,
              }}
              disabled={true}
            />
          </Box>

          {/* Import Formula Section */}
          {showImportFormula && (
            <Box className="import">
              <button
                onClick={handleOpenImportFormulaPopup}
                disabled={!isOwner}
                style={{
                  cursor: getCursorType(!isOwner),
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: "400",
                  fontFamily: "kenvue-sans-regular",
                  padding: "14px",
                  backgroundColor: getColor(!isOwner),
                  color: "white",
                  borderRadius: "24px",
                  border: "1px",
                  width: "100%",
                }}
              >
                Import Formula
              </button>
            </Box>
          )}

          {/* Description Section */}
          <Box className="description">
            <Typography
              fontWeight="700"
              fontSize="13.33px"
              sx={{ fontFamily: "kenvue-sans-regular", fontWeight: "700" }}
            >
              Formula Description
            </Typography>
            <TextField
              className="disabledfield"
              variant="standard"
              sx={{
                width: "100%",
              }}
              value={formulationData.description}
              disabled={disabled?.description || !isOwner}
              name="description"
              onChange={handleChange}
            />
          </Box>

          {/* Save and Cancel Buttons */}
          <Box
            className="button-container"
            sx={{
              position: "absolute",
              top: "0px",
              right: "10px",
              display: "flex",
              gap: "10px",
            }}
          >
            {<GetToastContainer />}
            {CheckCRUDAccess(usersData, "formulation") === 1 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(toggleSaveButton || taboutAutoSaveInProgress) ? 
                     <div style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px"
                    }}>
                    <img src={calculatingicon} alt="Calculating" />
                    <p className="calculating-text">{"Calculating"}</p>
                  </div> :
                  <button
                    style={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleClickSaveButton}
                    className={getSaveButtonClassName()}
                    disabled={!isSaveEnable}
                  >
                    {getButtonText()}
                    </button>
                  }
                </div>
              </div>
            )}
          </Box>
        </Box>

        <Box
          className="top-Net"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "96px",
          }}
        >
          <CustomTooltip
            title={
              errors.netContent &&
              "Net Content cannot be 0, Please enter the valid Net Content value"
            }
          >
            <div
              style={{
                
                color: getColor(disabled?.netContent),
              }}
              className={errors.netContent ? "netcontent_error" : "netContent"}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  fontWeight="700"
                  fontSize={"13.33px"}
                  sx={{
                    fontFamily: "kenvue-sans-regular !important",
                    fontWeight: "700",
                    color: getColor(disabled?.netContent || !isOwner),
                    // paddingLeft: errors.netContent ? "10px" : "0px",
                  }}
                  variant="subtitle1"
                >
                  Net Contents
                </Typography>
                <Typography
                  fontWeight="400"
                  fontSize={"13.33px"}
                  sx={{ fontFamily: "kenvue-sans-regular" }}
                  variant="subtitle1"
                  color={"red"}
                >
                  *
                </Typography>
                <Tooltipcommon
                  content={NET_CONTENT_TOOLTIP_CONTENT}
                  direction="net-content-top"
                  disable={disabled?.netContent || !isOwner}
                >
                  <InfoIcon />
                </Tooltipcommon>
              </div>
              <div style={{ width: "150px", display: "flex", marginTop: "-5px"  }}>
                <div style={{ width: "68px" }}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value={formulationData?.netContent ?? ""}
                    name="netContent"
                    onChange={handleChange}
                    onBlur={handelBlurUnit}
                    sx={{
                      "& input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "& input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "& input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    type="number"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    style={{
                     padding: "6px 0px",
                      cursor: getCursorType(mode === "view"),
                    }}
                    error={errors.netContent}
                    disabled={disabled?.netContent || !isOwner}
                    className="disabledfield"
                  />
                </div>

                <div style={{ width: "100px", marginTop: "-3px" }}>
                  <FormControl
                    sx={{
                      m: 0,
                      minWidth: "auto",
                      border: "none",
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                  >
                    <Select
                      sx={{
                        "& .css-18jcskp-MuiButtonBase-root-MuiMenuItem-root.Mui-TextFielded":
                          {
                            backgroundColor: "none",
                          },
                      }}
                      disabled={disabled?.netContent || !isOwner}
                      className="disabledfield"
                      value={formulationData.netContentUnit}
                      name="netContentUnit"
                      onChange={handleChange}
                      error={errors.netContentUnit}
                      style={{
                        width: "auto",
                        fontFamily: "kenvue-sans-regular",
                        fontWeight: "700",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "13px",
                        color: getColor(disabled?.netContent || !isOwner),
                      }}
                    >
                      {formulationMasterData.netContent?.map((option) => (
                        <MenuItem style={{fontWeight:700}} key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </CustomTooltip>
          <FormField
           width="188px"
            label="Production Zone"
            required
           
            tooltipContent={PRODUCT_ZONE_TOOLTIP_CONTENT}
            dropdownOptions={formulationMasterData.productionZone?.map(
              (zone) => ({ value: zone, label: zone })
            )}
            dropdownValue={formulationData.productionZone}
            onDropdownChange={handleChange}
            name="productionZone"
            dropDownWidth="167px"
            error={errors.productionZone}
            disabled={disabled?.productionZone || !isOwner}
          />
          <FormField
            width="164px"
            label="Sales Zone"
            required
            tooltipContent={SALES_ZONE_TOOLTIP_CONTENT}
            dropdownOptions={formulationMasterData.salesZone?.map((zone) => ({
              value: zone,
              label: zone,
            }))}
            dropdownValue={formulationData.salesZone}
            onDropdownChange={handleChange}
            name="salesZone"
            dropDownWidth="135px"
            error={errors.salesZone}
            disabled={disabled?.salesZone || !isOwner}
          />
        </Box>
        <Box
          className="product"
          sx={{

          }}
        >
          <div
            className={errors.productSegment ? "select_errorProduct" : "productSegment"}
          >
            <SelectFieldLable
              lable="Product Segment"
              tooltipLable={PRODUCT_SEGMENT_TOOLTIP_CONTENT}
              disable={disabled?.productSegment || !isOwner}
            />
            <FormControl
              sx={{
                border: "none",
                "& fieldset": {
                  border: "none",
                },
              }}
              error={!!error}
              fullWidth
            >
              <Select
                value={formulationData.productSegment}
                name="productSegment"
                onChange={handleChange}
                error={errors.productSegment}
                disabled={disabled?.productSegment || !isOwner}
                className="disabledfield"
                sx={{
                  width: "174px",
                  fontFamily: "kenvue-sans-regular",
                  fontWeight: "400",
                  fontSize: "14px",
                  color: getColor(disabled?.productSegment || !isOwner),
                }}
              >
                <MenuItem value="">Select</MenuItem>
                {formulationMasterData.segment?.map((product, index) => (
                  <MenuItem
                    key={`${index}+${product.productSegment}`}
                    value={product.productSegment}
                  >
                    {product.productSegment}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div
            className={errors.productSubSegment ? "select_errorProduct" : "productSubSegment"}
          
          >
            <SelectFieldLable
              lable="Product Sub-segment"
              tooltipLable={PRODUCT_SUB_SEGMENT_TOOLTIP_CONTENT}
              disable={disabled?.productSubSegment || !isOwner}
            />
            <FormControl
              sx={{
                border: "none",
                "& fieldset": {
                  border: "none",
                },
              }}
            >
              <Select
                value={formulationData.productSubSegment}
                name="productSubSegment"
                onChange={handleChange}
                error={isImportFormula && errors.productSubSegment}
                disabled={disabled?.productSubSegment || !isOwner}
                className="disabledfield"
                sx={{
                  "& .css-18jcskp-MuiButtonBase-root-MuiMenuItem-root.Mui-TextFielded":
                    {
                      backgroundColor: "none",
                    },
                  fontFamily: "kenvue-sans-regular",
                  fontWeight: "400",
                  fontSize: "14px",
                  width: "208px",
                }}
              >
                <MenuItem value="">Select</MenuItem>
                {subSegments?.map((subSegment, index) => (
                  <MenuItem key={`${index}+${subSegment}`} value={subSegment}>
                    {subSegment}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <CustomTooltip
            title={
              errors.useDose &&
              "Use Dose cannot be 0, Please enter the valid Use Dose value"
            }
          >
            <div
              className={errors.useDose ? "netcontent_error2" : "netContentArea"}
              style={{
               
                alignItems: "start",
                color: getColor(disabled?.useDose),
              }}
            >
              <SelectFieldLable
                lable="Use Dose"
                tooltipLable={USE_DOSE_TOOLTIP_CONTENT}
                disable={disabled?.useDose || !isOwner}
              />
              <div style={{ width: "150px", display: "flex", marginTop: "-5px" }}>
                <div style={{ width: "68px" }}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value={useDose}
                    name="useDose"
                    onChange={handleChange}
                    onBlur={handelBlurUnit}
                    error={errors.useDose}
                    disabled={disabled?.useDose || !isOwner}
                    className="disabledfield"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    style={{ padding: "6px 0px"}}
                  />
                </div>

                <div style={{ width: "100px", marginTop: "-3px" }}>
                  <FormControl
                    sx={{
                      m: 0,
                      minWidth: "auto",
                      border: "none",
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                  >
                    <Select
                      sx={{
                        "& .css-18jcskp-MuiButtonBase-root-MuiMenuItem-root.Mui-TextFielded":
                          {
                            backgroundColor: "none",
                            fontSize: "13px",
                          },
                      }}
                      value={formulationData.useDoseUnit}
                      name="useDoseUnit"
                      onChange={handleChange}
                      error={errors.useDoseUnit}
                      disabled={disabled?.useDose || !isOwner}
                      className="disabledfield"
                      style={{
                        width: "auto",
                        fontWeight: "700",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "13px",
                        fontFamily: "kenvue-sans-regular !important",
                      }}
                    >
                      {formulationMasterData.useDose?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </CustomTooltip>
          <FormField
            label="Use Scenario"
            required
            tooltipContent={USE_SCENARIO_TOLTIP_CONTENT}
            dropdownOptions={formulationMasterData.useScenario?.map((scenario) => ({
              value: scenario,
              label: scenario,
            }))}
            dropdownValue={formulationData.useScenario}
            onDropdownChange={handleChange}
            name="useScenario"
            dropDownWidth="135px"
            error={errors.useScenario}
            disabled={disabled?.useScenario || !isOwner}
          />
          <div className="Consumable-used-filed">
            <ConsumablesUsedField
              onHandleChange1={handleClick1}
              onHandleChange2={handleClick2}
              consumablesLable={formulationData.consumablesUsed}
              disabled={!isOwner}
            />
          </div>
        </Box>
      </Box>
      <div style={{ display: "flex", width: "100%", marginTop: "36px" }}>
        <div style={{ width: "auto", display: "flex" }}>
          <p
            style={{
              fontFamily: "kenvue-sans",
              fontWeight: "700",
              fontSize: "16px",
              margin: "0px",
            }}
          >
            Formula Composition
          </p>
          {!isExperimental && (
            <img
              src={lockImage}
              alt="lock-icon"
              style={{ marginLeft: "5px" }}
            />
          )}
        </div>
      </div>
      <br />
      <FormulationAndCompositionTable
        mode={mode}
        formulationRawMaterials={formulationData.rawMaterials}
        handelFormulationTableChanges={handelFormulationTableChanges}
        isClear={isClear}
      />
      <br />
      <FormulationLegends/>
      {/* <IntensityBar /> */}
      <PopupImportFormula
        key={dialogKey}
        open={importFormulaDialogOpen}
        onClose={handleCloseImportFormulaDialog}
        sendToParent={callChildData}
      />
      <DialogBox
        text={WARNING_MSG_CANCEL}
        buttonOneText="Cancel"
        buttonTwoText="Continue"
        open={showWariningMsg}
        onClose={handleCloseDialog}
        onClick={handleContinueDialogButton}
      />
      <DialogBox
        text={
          "You are editing the material composition of a formula. These changes are only captured in the SIP tool for simulation purposes. These changes will NOT reflect back in Concerto. The formula code will no longer be attached to this experimental assessment product"
        }
        buttonOneText="Cancel"
        buttonTwoText="Continue"
        open={isWarningEditDialog}
        onClose={handleCloseEditWarningDialog}
        onClick={handleContinueEditWarningDialogButton}
      />
    </>
  );
};
export default FormulaAndConsumer;
