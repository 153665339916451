import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { RawMaterialsData } from "../../../structures/formulation";
import { ProductDataContext } from "../../../contexts/productData/ProductDataContext";
import { ASSESSMENT_TYPE } from "../../../constants/String.constants";
import { useGetRawMaterialDataByKeyword } from "../../../hooks/UseGetProductDetails";
import { useDebounce } from "../../../hooks/UseDebounce";
import { CheckCRUDAccess } from "../../../helper/GenericFunctions";
import { ResultDataContext } from "../../../contexts/resultData/ResultDataContext";

interface IRawMaterialObject {
  rawMaterialId: string;
  tradeName: string;
  percentage: string;
}

interface FormulationAndCompositionTableProp {
  formulationRawMaterials: RawMaterialsData[];
  handelFormulationTableChanges: (
    formulationRawMaterials: RawMaterialsData[]
  ) => void;
  isClear: boolean;
}

const useFormulationTable = ({
  isClear,
  formulationRawMaterials,
  handelFormulationTableChanges,
}: FormulationAndCompositionTableProp) => {
  const { footPrintData } = useContext(ResultDataContext);
  const { usersData, assessmentsType } = useContext(ProductDataContext);
  const [rawMaterialPage, setRawMaterialPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const {
    mutate: fetchRawMaterialData,
    data,
    isLoading,
  } = useGetRawMaterialDataByKeyword(debouncedSearchTerm, rawMaterialPage);
  const [isSearchable, setIsSearchable] = useState<boolean>(false);
  const [rows, setRows] = useState<RawMaterialsData[]>(formulationRawMaterials);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [searchResults, setSearchResults] = useState<IRawMaterialObject[]>([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof RawMaterialsData | null>(null);
  const [isHoveredtotal, setIsHoveredtotal] = useState(false);
  const [isMaxPercatage, setIsMaxPercatage] = useState<boolean>(false);
  const [isSearchResultsOpen, setIsSearchResultsOpen] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLInputElement>(null);
  const [errors, setErrors] = useState<Map<string, string | null>>(new Map());
  const userAccess = CheckCRUDAccess(usersData, "assessment");
  const [isFormulaCompositionEditable, setIsFormulaCompositionEditable] =
    useState<boolean>(false); // true means formula composition can be edited
  const [inFocusRows, setInFocusRows] = useState<number[]>([]);
  const scaleFactor = 1e6;

  useEffect(() => {
    setIsFormulaCompositionEditable(
      assessmentsType === ASSESSMENT_TYPE.EXPERIMENTAL_ASSESSMENT &&
        userAccess === 1
    );
  }, [userAccess, assessmentsType]);

  const handleMassChange = (indexPosition: number, newMass: string) => {
    const updatedRows = rows.map((row, index) =>
      index === indexPosition ? { ...row, percentage: newMass } : row
    );
    setRows(updatedRows);
    handelFormulationTableChanges(updatedRows);
  };

  useEffect(() => {
    if (isSearchable) {
      if (debouncedSearchTerm !== "") {
        fetchRawMaterialData();
      } else {
        setSearchResults([]);
      }
    }
  }, [
    debouncedSearchTerm,
    setIsSearchable,
    fetchRawMaterialData,
    isSearchable,
  ]);

  useEffect(() => {
    if (data) {
      setSearchResults(data);
    }
  }, [data]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setIsSearchable(true);
    setIsSearchResultsOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const handleDeleteRow = (position: number) => {
    const updatedRows = rows.filter((_, index) => index !== position);
    setRows(updatedRows);
    handelFormulationTableChanges(updatedRows);
  };

  const handleSearchSelect = (result: RawMaterialsData) => {
    setRows([...rows, result]);
    handelFormulationTableChanges([...rows, result]);
    setSearchValue("");
    setSearchResults([]);
  };

  const handleMouseEnterWeight = () => {
    setIsHoveredtotal(true);
  };

  const handleMouseLeaveWeight = () => {
    setIsHoveredtotal(false);
  };

  /* START CODE - TOTAL WEIGHT */

  const getTotalWeight = useCallback(() => {
    return rows.reduce((total, row) => {
      const percentage = row?.percentage;
      if (
        percentage === "" ||
        percentage === null ||
        isNaN(parseFloat(percentage))
      ) {
        return total;
      }
      return (
        Math.round(
          (total + parseFloat(percentage) + Number.EPSILON) * scaleFactor
        ) / scaleFactor
      );
    }, 0);
  }, [rows]);

  const formatToTwoDecimalsWithoutRounding = (num: string | number): string => {
    const parsedNum = parseFloat(num.toString());
    if (isNaN(parsedNum)) return "0.00";
    return (Math.floor(parsedNum * 100) / 100).toFixed(2);
  };

  // Rounding to 6 decimals
  const formattedTotalWeight = useMemo(() => {
    if (isHoveredtotal) {
      if (parseFloat(getTotalWeight().toFixed(6)) === 100.0) {
        return getTotalWeight().toFixed(2);
      }
      return getTotalWeight().toFixed(6);
    }
    if (!isHoveredtotal) {
      if (getTotalWeight() === 100.0) {
        return parseFloat(
          formatToTwoDecimalsWithoutRounding(getTotalWeight())
        ).toFixed(2);
      } else {
        return getTotalWeight() > 100.0
          ? getTotalWeight().toString().slice(0, 6)
          : getTotalWeight().toString().slice(0, 5);
      }
    }
  }, [getTotalWeight, isHoveredtotal]);

  const getBottomPercentage = (key: string, row: RawMaterialsData) => {
    if (key === "envFootprint") {
      return row?.envFootprint;
    } else if (key === "carbonFootprint") {
      return row?.carbonFootprint;
    } else {
      return Number(row?.gaiaScore);
    }
  };

  const getTotalWeight_bottom = useCallback(
    (key: string) => {
      return rows.reduce((total_bottom, row) => {
        const percentage_bottom = getBottomPercentage(key, row);
        if (percentage_bottom === null || isNaN(percentage_bottom)) {
          return total_bottom;
        }
        return total_bottom + (percentage_bottom ?? 0);
      }, 0);
    },
    [rows]
  );

  const formattedTotalWeight_envFootprint =
    isHoveredtotal && getTotalWeight_bottom("envFootprint") < 100
      ? getTotalWeight_bottom("envFootprint").toFixed(6)
      : getTotalWeight_bottom("envFootprint").toFixed(2);

  const formattedTotalWeight_carbonFootprint =
    isHoveredtotal && getTotalWeight_bottom("carbonFootprint") < 100
      ? getTotalWeight_bottom("carbonFootprint").toFixed(6)
      : getTotalWeight_bottom("carbonFootprint").toFixed(2);

  const formattedTotalWeight_gaiaScore =
    isHoveredtotal && getTotalWeight_bottom("gaiaScore") < 100
      ? getTotalWeight_bottom("gaiaScore").toFixed(6)
      : getTotalWeight_bottom("gaiaScore").toFixed(2);

  /* END CODE - productEnvironmentalFootPrint  */

  const cancelChanges = useCallback(() => {
    setRows(formulationRawMaterials);
  }, [formulationRawMaterials]);

  const handleRequestSort = (property: keyof RawMaterialsData) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getColorByValue = (value: number) => {
    if (value <= 30) return "#CFDDEC";
    if (value <= 60) return "#3F7AB4";
    return "#0A316B";
  };

  const handleBlur = (indexPosition: string, value: string) => {
    const numValue = parseFloat(value);
    let errorMessage: string | null = null;
    if (numValue <= 0 || numValue > 100) {
      errorMessage =
        "Mass % Composition should be in range of 0-100% only, Please enter valid range";
    }
    setErrors((prevErrors) =>
      new Map(prevErrors).set(indexPosition?.toString(), errorMessage)
    );
  };

  useEffect(() => {
    const allDataFilled = rows.every(
      (row) =>
        row.tradeName && row.rawMaterialId && parseFloat(row.percentage) !== 0
    );
    setIsSaveEnabled(allDataFilled && getTotalWeight() === 100);
  }, [rows, isClear, isSaveEnabled, getTotalWeight]);

  useEffect(() => {
    Number(getTotalWeight()) === 100
      ? setIsMaxPercatage(true)
      : setIsMaxPercatage(false);
  }, [getTotalWeight]);

  useEffect(() => {
    if (isClear) {
      cancelChanges();
    }
  }, [isClear, cancelChanges]);

  useEffect(() => {
    if (formulationRawMaterials) {
      let updatedFormulationRawMaterials = formulationRawMaterials;
      if (footPrintData) {
        updatedFormulationRawMaterials = formulationRawMaterials.map(
          (item1) => {
            const item2 = footPrintData?.find(
              (item) => item.rawMaterialId === item1.rawMaterialId
            );
            return {
              ...item1,
              ...item2,
            };
          }
        );

      }
      setRows(updatedFormulationRawMaterials);
      setSearchValue("");
    }
  }, [formulationRawMaterials, footPrintData]);

  return {
    rows,
    isSaveEnabled,
    searchValue,
    searchResults,
    order,
    errors,
    orderBy,
    isHoveredtotal,
    handleMassChange,
    handleDeleteRow,
    handleSearchChange,
    handleSearchSelect,
    handleMouseEnterWeight,
    handleMouseLeaveWeight,
    getTotalWeight,
    formattedTotalWeight,
    cancelChanges,
    handleRequestSort,
    getColorByValue,
    isMaxPercatage,
    handleBlur,
    isFormulaCompositionEditable,
    setRawMaterialPage,
    rawMaterialPage,
    isLoading,
    formattedTotalWeight_envFootprint,
    formattedTotalWeight_carbonFootprint,
    formattedTotalWeight_gaiaScore,
    inFocusRows,
    setInFocusRows,
    anchorEl,
    isSearchResultsOpen,
  };
};

export default useFormulationTable;
